// Icons used by key metrics

export const icons = [
  'calendar',
  'ban',
  'chart-area',
  'asterisk',
  'university',
  'bars',
  'bolt',
  'briefcase',
  'building',
  'bookmark',
  'check-square',
  'cog',
  'clock',
  'cloud',
  'comment',
  'gem',
  'cubes',
  'compass',
  'envelope',
  'exchange-alt',
  'users',
  'globe-americas',
  'history',
  'home',
  'key',
  'chart-line',
  'plus',
  'minus',
  'chart-bar',
  'chart-pie',
  'dollar-sign'
];
