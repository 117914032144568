import './Connectors.scss';

import type { AppDispatch, RootState } from 'src';
import type { Connector } from 'src/types/Connector';

import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

import Header from '../../components/Header/Header';
import Loading from '../../components/Loading/Loading';
import {
  addConnector,
  addConnectorClose,
  deleteConnector,
  getConnectors
} from '../../redux/actions/Connectors';
import AddConnectorModal from './components/AddConnectorModal';
import ConnectorList from './components/ConnectorList';
import ConnectorSingle from './components/ConnectorSingle';
import DeleteConnectorModal from './components/DeleteConnectorModal';

type Props = {
  connectorAdded: Connector;
  connectors: Connector[];
  dispatch: AppDispatch;
  loading: boolean;
  submitting: boolean;
};
type State = {
  activeConnector: number;
  addConnectorModalOpen: boolean;
  deleteConnectorModalOpen: boolean;
};

class Connectors extends React.Component<Props, State> {
  state = {
    activeConnector: 0,
    addConnectorModalOpen: false,
    deleteConnectorModalOpen: false
  };

  componentDidMount() {
    document.title = 'Connectors';
    this.props.dispatch(getConnectors());
  }

  componentDidUpdate(prevProps: Props) {
    const { connectors } = this.props;
    if (
      connectors &&
      prevProps.connectors &&
      connectors.length !== prevProps.connectors.length
    )
      this.setState({ activeConnector: connectors.length - 1 });
  }

  handleAddConnector = (values: Connector) => {
    this.props.dispatch(addConnector(values));
  };

  handleAddConnectorClose = () => {
    if (this.props.connectorAdded) {
      this.props.dispatch(addConnectorClose());
      this.props.dispatch(getConnectors());
    }
  };

  handleDeleteConnector = () => {
    const { connectors, dispatch } = this.props;
    const activeConnector = this.state.activeConnector;
    const id = connectors[activeConnector].id;
    dispatch(deleteConnector(id)).then(() => {
      dispatch(getConnectors());
      this.setState({ deleteConnectorModalOpen: false });
    });
  };

  render() {
    const { connectorAdded, connectors, loading, submitting } = this.props;
    const { activeConnector, addConnectorModalOpen, deleteConnectorModalOpen } =
      this.state;

    return (
      <div data-testid="connectors">
        {loading ? (
          <Loading />
        ) : (
          <div id="connectors">
            <AddConnectorModal
              connectorAdded={connectorAdded}
              handleClose={this.handleAddConnectorClose}
              handleSubmit={this.handleAddConnector}
              isOpen={addConnectorModalOpen}
              submitting={submitting}
              toggle={this.toggleAddConnectorModal}
            />
            <DeleteConnectorModal
              connector={connectors ? connectors[activeConnector] : null}
              handleSubmit={this.handleDeleteConnector}
              isOpen={deleteConnectorModalOpen}
              submitting={submitting}
              toggle={this.toggleDeleteConnectorModal}
            />
            <Header backLink="/" backLinkText="Home" title="Connectors" />
            <Container>
              <Row>
                <Col className="list-column" xl="3">
                  <ConnectorList
                    active={activeConnector}
                    connectors={connectors}
                    setActive={this.setActiveConnector}
                    toggle={this.toggleAddConnectorModal}
                  />
                </Col>
                <Col xl="9">
                  <ConnectorSingle
                    connector={connectors ? connectors[activeConnector] : null}
                    toggleDelete={this.toggleDeleteConnectorModal}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    );
  }

  setActiveConnector = (index: number) => {
    this.setState({ activeConnector: index });
  };

  toggleAddConnectorModal = () => {
    this.setState({ addConnectorModalOpen: !this.state.addConnectorModalOpen });
  };

  toggleDeleteConnectorModal = () => {
    this.setState({
      deleteConnectorModalOpen: !this.state.deleteConnectorModalOpen
    });
  };
}

const mapStateToProps = (store: RootState) => {
  return {
    connectorAdded: store.Connectors.connectorAdded,
    connectors: store.Connectors.connectors,
    error: store.Connectors.error,
    loading: store.Connectors.loading,
    submitting: store.Connectors.submitting
  };
};

export default connect(mapStateToProps)(Connectors);
