import './App.scss';

import type { RootState } from 'src';

import React from 'react';
import { connect } from 'react-redux';

import AppRoutes from './AppRoutes';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
};

class App extends React.Component<Props> {
  render() {
    const { error } = this.props;
    return (
      // This is defined as a string in maptStateToProps but ErrorBoundary expects something different - what should it actually be?
      <ErrorBoundary serverError={error}>
        <AppRoutes />
      </ErrorBoundary>
    );
  }
}
const mapStateToProps = (store: RootState) => {
  return {
    error: store.Error.error,
    user: store.Auth.user
  };
};

export default connect(mapStateToProps)(App);
