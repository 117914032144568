import type { Component } from 'src/types/Component';
import type { Offering, OfferingData } from 'src/types/Offering';

import { Reducer } from 'redux';

import {
  ADD_COMPONENT,
  ADD_COMPONENT_FAIL,
  ADD_COMPONENT_SUCCESS,
  ADD_OFFERING_DATA,
  ADD_OFFERING_DATA_FAIL,
  ADD_OFFERING_DATA_SUCCESS,
  DELETE_COMPONENT,
  DELETE_COMPONENT_FAIL,
  DELETE_COMPONENT_SUCCESS,
  FORCE_LOCK_COMPONENT,
  FORCE_LOCK_COMPONENT_FAIL,
  FORCE_LOCK_COMPONENT_SUCCESS,
  GET_COMPONENT,
  GET_COMPONENT_FAIL,
  GET_COMPONENT_SUCCESS,
  GET_COMPONENTS,
  GET_COMPONENTS_FAIL,
  GET_COMPONENTS_SUCCESS,
  GET_OFFERING,
  GET_OFFERING_DATA,
  GET_OFFERING_DATA_FAIL,
  GET_OFFERING_DATA_SUCCESS,
  GET_OFFERING_FAIL,
  GET_OFFERING_SUCCESS,
  LOCK_COMPONENT,
  LOCK_COMPONENT_FAIL,
  LOCK_COMPONENT_SUCCESS,
  SAVE_COMPONENT,
  SAVE_COMPONENT_FAIL,
  SAVE_COMPONENT_SUCCESS,
  SAVE_OFFERING,
  SAVE_OFFERING_DATA,
  SAVE_OFFERING_DATA_FAIL,
  SAVE_OFFERING_DATA_SUCCESS,
  SAVE_OFFERING_FAIL,
  SAVE_OFFERING_SUCCESS,
  SELECT_COMPONENT,
  SELECT_LOCKED_COMPONENT,
  UNLOCK_COMPONENT,
  UNLOCK_COMPONENT_FAIL,
  UNLOCK_COMPONENT_SUCCESS,
  UPDATE_COMPONENT_DATA,
  UPDATE_OFFERING_FIELDS,
  UPLOAD_MEDIA,
  UPLOAD_MEDIA_FAIL,
  UPLOAD_MEDIA_SUCCESS
} from '../../redux/actions/Offering';
import { BaseAction, BaseState } from '../types/Base';

type OfferingState = BaseState & {
  components?: Component[];
  editingComponentData?: Component;
  editingOfferingData?: Offering;
  loadingComponent?: number;
  offering?: Offering;
  offeringData?: OfferingData[];
  selectedComponentId?: number;
  selectedLockedComponentId?: number;
};

type OfferingAction = Component & Component[] & Offering & OfferingData[];

export const initialState = {
  loading: false,
  offeringData: [],
  submitting: false
};

const offeringReducer: Reducer<
  OfferingState,
  BaseAction<OfferingAction, OfferingAction>
> = (state = initialState, action = { type: '' }) => {
  switch (action.type) {
    case GET_OFFERING:
      return {
        ...initialState,
        loading: true
      };
    case GET_COMPONENTS:
    case GET_OFFERING_DATA:
    case SAVE_OFFERING_DATA:
    case SAVE_COMPONENT:
    case SAVE_OFFERING:
    case UNLOCK_COMPONENT:
      return {
        ...state,
        loading: true
      };
    case UNLOCK_COMPONENT_FAIL:
    case UNLOCK_COMPONENT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case ADD_COMPONENT:
    case DELETE_COMPONENT:
    case FORCE_LOCK_COMPONENT:
    case UPLOAD_MEDIA:
    case ADD_OFFERING_DATA:
      return {
        ...state,
        submitting: true
      };
    case GET_OFFERING_SUCCESS:
      return {
        ...state,
        loading: false,
        offering: action.payload
      };
    case GET_OFFERING_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        offeringData: action.payload
      };
    case GET_COMPONENTS_SUCCESS:
      return {
        ...state,
        components: action.payload,
        editingComponentData: null,
        loading: false,
        loadingComponent: null
      };
    case GET_COMPONENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingComponent: null
      };
    case ADD_COMPONENT_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedComponentId: action.id,
        submitting: false
      };
    case SELECT_COMPONENT:
      return {
        ...state,
        loadingComponent: action.id,
        selectedComponentId: action.id,
        selectedLockedComponentId: null
      };
    case SELECT_LOCKED_COMPONENT:
      return {
        ...state,
        loadingComponent: action.id,
        selectedComponentId: null,
        selectedLockedComponentId: action.id
      };
    case UPDATE_COMPONENT_DATA:
      return {
        ...state,
        editingComponentData: {
          collapse: action.collapse,
          data: action.data,
          id: action.id,
          title: action.title
        }
      };
    case UPDATE_OFFERING_FIELDS:
      return {
        ...state,
        editingOfferingData: {
          data: action.data
        }
      };
    case SAVE_COMPONENT_SUCCESS:
      return {
        ...state,
        editingComponentData: null
      };
    case SAVE_OFFERING_SUCCESS:
      return {
        ...state,
        editingOfferingData: null,
        loading: false
      };
    case FORCE_LOCK_COMPONENT_FAIL:
    case FORCE_LOCK_COMPONENT_SUCCESS:
    case LOCK_COMPONENT_FAIL:
    case LOCK_COMPONENT_SUCCESS:
    case GET_OFFERING_FAIL:
    case GET_OFFERING_DATA_FAIL:
    case GET_COMPONENTS_FAIL:
    case GET_COMPONENT_FAIL:
    case ADD_COMPONENT_FAIL:
    case DELETE_COMPONENT_FAIL:
    case DELETE_COMPONENT_SUCCESS:
    case SAVE_COMPONENT_FAIL:
    case SAVE_OFFERING_FAIL:
    case UPLOAD_MEDIA_SUCCESS:
    case UPLOAD_MEDIA_FAIL:
    case ADD_OFFERING_DATA_SUCCESS:
    case ADD_OFFERING_DATA_FAIL:
    case SAVE_OFFERING_DATA_SUCCESS:
    case SAVE_OFFERING_DATA_FAIL:
      return {
        ...state,
        loading: false,
        submitting: false
      };
    case GET_COMPONENT:
    case LOCK_COMPONENT: {
      return state;
    }
    default:
      return state;
  }
};

export default offeringReducer;
