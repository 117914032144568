// Generic, resuable patterns

const allCharacters = {
  description: `can only contain letters, numbers, spaces and '"-:()&$!?%;.,_/*`,
  pattern: /^[\sa-z0-9'":()&$!?%;,_.-/*]*$/i
};

const lettersSpaceNoStartOrEndSpace = {
  description:
    'can only contain letters and spaces, and cannot start or end with a space',
  pattern: /^[a-z]+(\s+[a-z-]+)*$/i
};

const lettersSpaceHyphen = {
  description:
    'must begin with a letter, and can only contain letters, spaces and hyphens',
  pattern: /^([a-z])+([a-z-\s]+)*$/i
};

const noStartOrEndSpaceAllCharacters = {
  description: `cannot start or end with a space, and can only contain letters, numbers, spaces and '"-:()&$!?%;.,_/*`,
  pattern: /^[-a-z0-9'":()&$!?%;,_.-]+(\s+[-a-z0-9'":()&$!?%;,_.-/*]+)*$/i
};

// Specific patterns

export const soundcloudUrl = {
  description:
    'SoundCloud URLs must be in the following format: https://soundcloud.com/user/track',
  pattern: /^(http(s)?:\/\/)?((w){3}.)?soundcloud\.com\/.\/?.+/
};

export const youtubeUrl = {
  description:
    'YouTube URLs must be in the following format: https://www.youtube.com/watch?v=videoID',
  pattern: /^(http(s)?:\/\/)?((w){3}.)?youtube\.com\/watch\?v=?.+/
};

export const vimeoUrl = {
  description:
    ' Vimeo URLs must be in the following format: https://vimeo.com/videoID',
  pattern: /^(http(s)?:\/\/)?((w){3}.)?vimeo\.com\/.\/?.+/
};

export const password = {
  description: 'Password can only contain numbers, letters, and !@#$%^&*',
  pattern: /^[a-zA-Z0-9!@#$%^&*]+$/
};

export const personName = {
  description: "can only contain letters , spaces and ,.'-",
  pattern: /^[a-z ,.'-]+$/i
};

export const componentTitle = noStartOrEndSpaceAllCharacters;
export const connectorName = lettersSpaceHyphen;
export const fileDescription = noStartOrEndSpaceAllCharacters;
export const fileName = noStartOrEndSpaceAllCharacters;
export const keyMetricsHelpText = noStartOrEndSpaceAllCharacters;
export const keyMetricsName = lettersSpaceNoStartOrEndSpace;
export const keyMetricsValue = noStartOrEndSpaceAllCharacters;
export const footnotes = allCharacters;
export const locationName = noStartOrEndSpaceAllCharacters;
export const mediaDescription = noStartOrEndSpaceAllCharacters;
export const mediaName = noStartOrEndSpaceAllCharacters;
export const offeringName = noStartOrEndSpaceAllCharacters;
export const sectionTitle = noStartOrEndSpaceAllCharacters;
