import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import ButtonPlus from '../../../components/ButtonPlus/ButtonPlus';

type Props = {
  isOpen: boolean;
  publishOffering: () => void;
  submitting: boolean;
  toggle: () => void;
};

export default class PublishOfferingModal extends React.Component<Props> {
  render() {
    const { isOpen, publishOffering, submitting, toggle } = this.props;

    return (
      <Modal centered isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Complete Offering</ModalHeader>
        <ModalBody>
          <p>
            You are about to update the offering to completed status. A
            completed offering can be published and made visible to the public.
            Are you sure you want to do this?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <ButtonPlus
            color="primary"
            data-testid="publishSubmitButton"
            loading={submitting}
            onClick={publishOffering}
            style={{ width: '190px' }}
          >
            Complete Offering
          </ButtonPlus>
        </ModalFooter>
      </Modal>
    );
  }
}
