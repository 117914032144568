import type { Component } from 'src/types/Component';

import React from 'react';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  onChange: (component: Component, values: Component) => void;
};
type State = {
  firstColumnIsHeader?: boolean;
  hasFooter?: boolean;
  hasHeader?: boolean;
};
export default class InspectorTable extends React.Component<Props, State> {
  state: State = {
    firstColumnIsHeader: false,
    hasFooter: false,
    hasHeader: false
  };

  componentDidMount() {
    this.setBold();
  }

  componentDidUpdate(prevProps) {
    const { componentData, editingComponentData } = this.props;
    const { firstColumnIsHeader, hasFooter, hasHeader } = this.state;
    // Only update hasHeader/hasFooter/firstColumnIsHeader state when one of those properties differs from editingComponentData
    // Just checking if any property of editingComponentData has changed (as other components do) would
    // trigger too often with tables
    // If the component ID has changed then additionally setBold
    if (
      componentData.id !== prevProps.componentData.id ||
      (editingComponentData &&
        editingComponentData.data &&
        (editingComponentData.data.hasHeader !== hasHeader ||
          editingComponentData.data.hasFooter !== hasFooter ||
          editingComponentData.data.firstColumnIsHeader !==
            firstColumnIsHeader))
    )
      this.setBold();
  }

  render() {
    const { firstColumnIsHeader, hasFooter, hasHeader } = this.state;

    return (
      <div className="inspector-fields">
        <div className="checkbox-group">
          <input
            checked={Boolean(hasHeader)}
            id="header"
            name="header"
            onChange={() => this.toggleBold('header')}
            type="checkbox"
          />
          <label htmlFor="header">First row is header</label>
        </div>
        <div className="checkbox-group">
          <input
            checked={Boolean(firstColumnIsHeader)}
            id="firstColumn"
            name="firstColumn"
            onChange={() => this.toggleBold('firstColumn')}
            type="checkbox"
          />
          <label htmlFor="firstColumn">First column is header</label>
        </div>
        <div className="checkbox-group">
          <input
            checked={Boolean(hasFooter)}
            id="footer"
            name="footer"
            onChange={() => this.toggleBold('footer')}
            type="checkbox"
          />
          <label htmlFor="footer">Last row is summary</label>
        </div>
      </div>
    );
  }

  setBold = () => {
    const { componentData, editingComponentData } = this.props;
    const newState: State = {};
    let currentComponentData = componentData;
    const isEditing =
      editingComponentData && editingComponentData.id === componentData.id;
    if (isEditing) currentComponentData = editingComponentData;

    newState.hasHeader = currentComponentData.data.hasHeader;
    newState.hasFooter = currentComponentData.data.hasFooter;
    newState.firstColumnIsHeader =
      currentComponentData.data.firstColumnIsHeader;

    this.setState(newState);
  };

  toggleBold = group => {
    const { componentData, editingComponentData, onChange } = this.props;
    const existingData = editingComponentData || componentData;

    const values: Component = {
      data: {}
    };

    if (group === 'header')
      values.data.hasHeader = !existingData.data.hasHeader;
    if (group === 'footer')
      values.data.hasFooter = !existingData.data.hasFooter;
    if (group === 'firstColumn')
      values.data.firstColumnIsHeader = !existingData.data.firstColumnIsHeader;

    onChange(componentData, values);
  };
}
