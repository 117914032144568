import type { Component } from 'src/types/Component';

import React from 'react';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  onChange: (component: Component, values: Component) => void;
};

export default class CollapseToggle extends React.Component<Props> {
  render() {
    const { componentData, editingComponentData } = this.props;
    const currentData =
      editingComponentData && editingComponentData.id === componentData.id
        ? editingComponentData
        : componentData;
    const collapse = currentData.collapse;

    return (
      <div className="clear-both" id="collapse-toggle">
        <div className="checkbox-group">
          <input
            checked={Boolean(collapse)}
            id="collapse"
            onChange={this.toggleCollapse}
            type="checkbox"
          />
          <label htmlFor="collapse">Collapse component</label>
        </div>
      </div>
    );
  }

  toggleCollapse = () => {
    const { componentData, editingComponentData, onChange } = this.props;
    const currentData = editingComponentData || componentData;

    const values = {
      collapse: !currentData.collapse
    };

    onChange(componentData, values);
  };
}
