import './CanvasParallel.scss';

import type { Component, ComponentType } from 'src/types/Component';

import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';

import CanvasComponent from '../CanvasComponent';

type Props = {
  active?: boolean;
  addComponent?: (id: number, types: ComponentType[], number?: number) => void;
  componentData?: Component;
  depth?: number;
  loading?: boolean;
  onChange?: (component: Component, values: Component) => void;
  parallelChildren?: Component[];
  selectedComponentId?: number;
};

export default class CanvasParallel extends React.Component<Props> {
  render() {
    const {
      addComponent,
      componentData,
      loading,
      parallelChildren,
      selectedComponentId
    } = this.props;
    const leftComponent = parallelChildren.find(
      component => component.hpos === 0
    );
    const rightComponent = parallelChildren.find(
      component => component.hpos === 1
    );
    const helperText = (
      <p className="helper-text">
        Preview the offering to ensure that components placed in parallel
        columns have adequate space
      </p>
    );

    return (
      <div className="overflow-hidden">
        <Container>
          <Row>
            <Col className="col-left" data-testid="parallelLeft" xs="6">
              {leftComponent ? (
                <CanvasComponent
                  {...this.props}
                  active={selectedComponentId === leftComponent.id && !loading}
                  componentData={leftComponent}
                />
              ) : (
                <div>
                  {helperText}
                  <Button
                    className="add-component-button"
                    color="secondary"
                    onClick={() =>
                      addComponent(componentData.id, [
                        'section',
                        'section-parallel',
                        'hero'
                      ])
                    }
                    outline
                  >
                    Add Left Component
                  </Button>
                </div>
              )}
            </Col>
            <Col className="col-right" data-testid="parallelRight" xs="6">
              {rightComponent ? (
                <CanvasComponent
                  {...this.props}
                  active={selectedComponentId === rightComponent.id && !loading}
                  componentData={rightComponent}
                />
              ) : (
                <div>
                  {helperText}
                  <Button
                    className="add-component-button"
                    color="secondary"
                    onClick={() =>
                      addComponent(
                        componentData.id,
                        ['section', 'section-parallel', 'hero'],
                        1
                      )
                    }
                    outline
                  >
                    Add Right Component
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
