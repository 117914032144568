import './Canvas.scss';

import type {
  Component,
  ComponentLocation,
  ComponentMetricData,
  ComponentType
} from 'src/types/Component';
import type { Image } from 'src/types/Image';
import type { MetricData } from 'src/types/MetricData';
import type { OfferingData } from 'src/types/Offering';
import type { UIState } from 'src/types/UIState';

import React from 'react';
import { getFlatDataFromTree, getTreeFromFlatData } from 'react-sortable-tree';
import { Button } from 'reactstrap';

import CanvasComponent from './CanvasComponent';

type Props = {
  addComponentClick: (
    parentId?: number,
    disabledComponentTypes?: ComponentType[],
    position?: number
  ) => void;
  components: Component[];
  componentUIState: UIState[];
  editingComponentData: Component;
  handleAddOfferingData: (data: ComponentMetricData) => Promise<void>;
  handleCanvasClick: () => void;
  handleComponentLockStatus: (id: number) => Promise<boolean>;
  handleGetOfferingData: (noLoading: true) => void;
  handleImageUpload: (image: File) => Promise<Image>;
  handleLockComponent: (id: number) => void;
  handleMapUpdate: (component: Component, values: Component) => void;
  handleSaveOfferingData: (id: number, data: MetricData) => Promise<void>;
  handleUploadMedia: (
    component: Component,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    editingItemIndex: number
  ) => void;
  inspectorOpen: boolean;
  loadingComponent: number;
  offeringData: OfferingData[];
  onChange: (component: Component, values: Component) => void;
  selectComponent: (id: number, isLocked: boolean) => void;
  selectedComponentId: number;
  selectedLocation: ComponentLocation;
  submitting: boolean;
  updateComponentUIState: (
    componentId: number,
    field: number | string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => void;
  userEmail: string;
};

export default class Canvas extends React.Component<Props> {
  findParent = (node, data) => {
    return data.find(component => node.parentId === component.id);
  };

  getNodeDepth = (node, data) => {
    let depth = 0;
    let isLast = false;
    let last = node;
    while (!isLast) {
      if (last.parentId == null) {
        isLast = true;
      } else {
        last = this.findParent(last, data);
        depth++;
      }
    }
    return depth;
  };

  handleClick = e => {
    if (e.target.id === 'canvas') this.props.handleCanvasClick();
  };

  render() {
    const {
      addComponentClick,
      components,
      componentUIState,
      editingComponentData,
      handleAddOfferingData,
      handleComponentLockStatus,
      handleGetOfferingData,
      handleImageUpload,
      handleLockComponent,
      handleMapUpdate,
      handleSaveOfferingData,
      handleUploadMedia,
      inspectorOpen,
      loadingComponent,
      offeringData,
      onChange,
      selectComponent,
      selectedComponentId,
      selectedLocation,
      submitting,
      updateComponentUIState,
      userEmail
    } = this.props;

    let sortedFlatComponents = components;

    if (components) {
      // Arrange in the sorted form
      const sortedComponents = components.sort((a, b) => {
        if (a.vpos < b.vpos) {
          return -1;
        }
        if (a.vpos > b.vpos) {
          return 1;
        }
        return 0;
      });

      sortedFlatComponents = getFlatDataFromTree({
        getNodeKey: node => node.id,
        ignoreCollapsed: false,
        // Need to create tree structure of sorting components
        treeData: getTreeFromFlatData({
          flatData: sortedComponents,
          getKey: node => node.id,
          getParentKey: node => node.parentId,
          rootKey: null
        })
      }).map(({ node }) => node);
    }

    return (
      <div
        className={inspectorOpen ? '' : 'inspector-closed'}
        data-testid="canvas"
        id="canvas"
        onKeyDown={() => {}}
        onMouseDown={this.handleClick}
        role="button"
        tabIndex={0}
      >
        {sortedFlatComponents && sortedFlatComponents.length ? (
          sortedFlatComponents.map(component => {
            // Exclude components within parallel columns from rendering directly in the canvas
            let hasParallelParent = false;
            if (component.parentId) {
              components.forEach(parentComponent => {
                if (
                  parentComponent.id === component.parentId &&
                  parentComponent.type === 'section-parallel'
                )
                  hasParallelParent = true;
              });
            }

            return (
              !hasParallelParent && (
                <CanvasComponent
                  active={
                    component.id === selectedComponentId && !loadingComponent
                  }
                  addComponent={addComponentClick}
                  componentData={component}
                  components={components}
                  componentUIState={componentUIState}
                  depth={this.getNodeDepth(component, sortedFlatComponents)}
                  editingComponentData={editingComponentData}
                  handleAddOfferingData={handleAddOfferingData}
                  handleComponentLockStatus={handleComponentLockStatus}
                  handleGetOfferingData={handleGetOfferingData}
                  handleImageUpload={handleImageUpload}
                  handleLockComponent={handleLockComponent}
                  handleMapUpdate={handleMapUpdate}
                  handleSaveOfferingData={handleSaveOfferingData}
                  handleUploadMedia={handleUploadMedia}
                  key={component.id}
                  loading={component.id === loadingComponent}
                  offeringData={offeringData}
                  onChange={onChange}
                  selectComponent={selectComponent}
                  selectedComponentId={selectedComponentId}
                  selectedLocation={selectedLocation}
                  submitting={submitting}
                  updateComponentUIState={updateComponentUIState}
                  userEmail={userEmail}
                />
              )
            );
          })
        ) : (
          <div className="no-components">
            <p>There are no components in the offering</p>
          </div>
        )}
        <div className="add-component-wrap">
          <Button
            color="secondary"
            onClick={() => addComponentClick()}
            outline
            title="Add a component"
          >
            Add a Component
          </Button>
        </div>
      </div>
    );
  }
}
