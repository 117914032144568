import './ComponentTitleInput.scss';

import type { Component } from 'src/types/Component';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { Alert, Input } from 'reactstrap';
import * as Yup from 'yup';

import { debounce } from '../../../../helpers';
import { componentTitle } from '../../../../regex';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  onChange: (component: Component, values: Component) => void;
};
export default class ComponentTitle extends React.Component<Props> {
  handleChange = debounce(title => {
    const { componentData, onChange } = this.props;
    const values = {
      title: title || null
    };
    onChange(componentData, values);
  }, 100);

  schema = Yup.object().shape({
    title: Yup.string()
      .nullable()
      .max(255, 'Title must be less than 255 characters')
      .matches(componentTitle.pattern, {
        excludeEmptyString: true,
        message: `Component title ${componentTitle.description}`
      })
  });

  render() {
    const { componentData } = this.props;

    return (
      <div id="component-title-input">
        <Formik
          enableReinitialize
          initialValues={{
            title: componentData.title || ''
          }}
          onSubmit={() => null}
          render={() => (
            <Form placeholder={undefined}>
              <label htmlFor="component-title">Title</label>
              <Field
                id="component-title"
                name="title"
                render={({ field, form }) => (
                  <Input
                    {...field}
                    data-testid="inspectorComponentTitle"
                    onChange={e => {
                      const value = e.target.value;
                      form.setFieldValue('title', value);
                      this.schema
                        .isValid({
                          title: value
                        })
                        .then(valid => {
                          if (valid) this.handleChange(value);
                        });
                    }}
                  />
                )}
              />
              <ErrorMessage name="title" render={this.renderError} />
            </Form>
          )}
          validationSchema={this.schema}
        />
      </div>
    );
  }

  renderError = message => <Alert color="danger">{message}</Alert>;
}
