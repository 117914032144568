/* global google */

import './MapComponent.scss';

import type { ComponentLocation } from 'src/types/Component';

import markers from 'fontawesome-markers';
import React, { useEffect, useRef } from 'react';
import {
  GoogleMap,
  InfoWindow,
  withGoogleMap,
  WithGoogleMapProps,
  withScriptjs
} from 'react-google-maps';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';

import { getToneFromBackground } from '../../../../helpers';
import mapStyles from '../helpers/mapStyles';
type Props = {
  componentIsSelected: boolean;
  infoName: string;
  infoOpen: boolean;
  locations: ComponentLocation[];
  selectedLocation: ComponentLocation;
  setZoom: (zoom: number) => void;
  showInfo: (name: string) => void;
};
const MapComponent = withScriptjs<Props & WithGoogleMapProps>(
  withGoogleMap(props => {
    const {
      componentIsSelected,
      infoName,
      infoOpen,
      locations,
      selectedLocation,
      setZoom,
      showInfo
    } = props;

    const updateMap = (map, locationsUpdate, selectedLocationUpdate) => {
      if (map && locations) {
        const bounds = new google.maps.LatLngBounds();
        locationsUpdate.forEach(location => {
          const position = new google.maps.LatLng(location.lat, location.lng);
          bounds.extend(position);
        });
        // Don't zoom in too far on only one marker
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
          const extendPoint1 = new google.maps.LatLng(
            bounds.getNorthEast().lat() + 0.015,
            bounds.getNorthEast().lng() + 0.015
          );
          const extendPoint2 = new google.maps.LatLng(
            bounds.getNorthEast().lat() - 0.015,
            bounds.getNorthEast().lng() - 0.015
          );
          bounds.extend(extendPoint1);
          bounds.extend(extendPoint2);
        }
        map.fitBounds(bounds);
        if (componentIsSelected) setZoom(map.getZoom());
        if (selectedLocationUpdate) {
          const position = new google.maps.LatLng(
            selectedLocationUpdate.lat,
            selectedLocationUpdate.lng
          );
          map.panTo(position);
        }
      }
    };

    const mapRef = useRef();

    useEffect(() => {
      updateMap(mapRef.current, locations, selectedLocation);
    });

    return (
      // eslint-disable-next-line
      //@ts-ignore
      <GoogleMap
        defaultOptions={{ styles: mapStyles }}
        maxZoom={15}
        ref={mapRef}
      >
        {locations.map(location => [
          <MarkerWithLabel
            icon={{
              anchor: new google.maps.Point(18, 0),
              fillColor: location.color,
              fillOpacity: 1,
              path: markers.MAP_MARKER,
              scale: location === selectedLocation ? 0.8 : 0.6,
              strokeColor: '#000',
              strokeOpacity: 0.5
            }}
            key={`${location.lat},${location.lng}`}
            labelAnchor={{ x: 0, y: 0 }}
            labelClass={`map-marker-label ${
              location === selectedLocation ? 'active' : ''
            }`}
            labelStyle={{
              background: location.color,
              color: getToneFromBackground(location.color)
            }}
            onClick={() => showInfo(location.name)}
            position={{ lat: location.lat, lng: location.lng }}
          >
            <div>{location.label}</div>
          </MarkerWithLabel>,
          infoOpen && infoName === location.name && (
            /* @ts-expect-error same error as above */
            <InfoWindow
              key={`${location.lat},${location.lng}-info`}
              onCloseClick={showInfo}
              options={{
                pixelOffset: new google.maps.Size(0, -38)
              }}
              position={{ lat: location.lat, lng: location.lng }}
            >
              <div
                style={{
                  opacity: 0.75,
                  padding: '6px'
                }}
              >
                <span>{location.name}</span>
              </div>
            </InfoWindow>
          )
        ])}
      </GoogleMap>
    );
  })
);

export default MapComponent;
