import type { Component } from 'src/types/Component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import DeleteConfirmationModal from '../shared/DeleteConfirmationModal';
import MediaModal from '../shared/MediaModal';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  onChange: (component: Component, data: Component) => void;
  submitting: boolean;
};
type State = {
  addModalOpen?: boolean;
  deleteModalOpen?: boolean;
  imageDeleted?: boolean;
  mediaDeleted?: boolean;
};
export default class InspectorMedia extends React.Component<Props, State> {
  state: State = {
    addModalOpen: false,
    deleteModalOpen: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.editingComponentData !== this.props.editingComponentData)
      this.setState({ addModalOpen: false });
    if (
      prevProps.editingComponentData &&
      !prevProps.editingComponentData.data &&
      this.props.editingComponentData.data
    )
      this.setState({ mediaDeleted: false });
  }

  deleteMedia = () => {
    const { componentData, onChange } = this.props;
    onChange(componentData, {
      data: {
        description: null,
        name: null,
        source: null,
        url: null
      }
    });
    this.setState({ mediaDeleted: true });
    this.toggleDeleteModal();
  };

  render() {
    const { componentData, editingComponentData, onChange, submitting } =
      this.props;
    const { addModalOpen, deleteModalOpen, mediaDeleted } = this.state;
    let mediaData = componentData.data;
    if (!mediaDeleted && editingComponentData?.id === componentData.id) {
      mediaData = editingComponentData.data;
    }
    const icon = mediaData.source === 'vimeo' ? 'vimeo-v' : mediaData.source;

    return (
      <div>
        <div className="inspector-fields">
          {mediaData?.url ? (
            <div className="selectable-list-item active">
              <FontAwesomeIcon
                className="component-icon"
                /* @ts-expect-error we import all icons in App.js in order to use this as is */
                icon={['fab', icon]}
              />
              <span className="label">{mediaData.name}</span>
              <button
                className="edit"
                data-testid="editMedia"
                onClick={this.toggleAddModal}
                type="button"
              >
                <FontAwesomeIcon className="component-icon" icon="pencil-alt" />
              </button>
              <button
                className="delete"
                data-testid="deleteMedia"
                onClick={this.toggleDeleteModal}
                type="button"
              >
                ×
              </button>
            </div>
          ) : (
            <p className="gray-med no-data">There is no media</p>
          )}
        </div>
        <MediaModal
          componentData={componentData}
          editing
          editingComponentData={editingComponentData}
          handleSubmit={(componentDataToSubmit, values) =>
            onChange(componentDataToSubmit, { data: values })
          }
          isOpen={addModalOpen}
          submitting={submitting}
          toggle={this.toggleAddModal}
        />
        <DeleteConfirmationModal
          handleDelete={this.deleteMedia}
          isOpen={deleteModalOpen}
          toggle={this.toggleDeleteModal}
          type="media"
        />
      </div>
    );
  }

  toggleAddModal = () => {
    this.setState({
      addModalOpen: !this.state.addModalOpen
    });
  };

  toggleDeleteModal = () => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen
    });
  };
}
