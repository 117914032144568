import './ConnectorSingle.scss';

import dateFormat from 'dateformat';
import React from 'react';
import { Button } from 'reactstrap';

import { Connector } from '../../../types/Connector';

type Props = {
  connector: Connector;
  toggleDelete: () => void;
};

export default class ConnectorSingle extends React.Component<Props> {
  render() {
    const { connector, toggleDelete } = this.props;

    return (
      <div data-testid="connectorSingle" id="connector-single">
        {connector ? (
          <div>
            <h2 className="margin-xx">{connector.name}</h2>
            <div className="details">
              <div className="detail">
                Created on{' '}
                <span>
                  {dateFormat(connector.createdAt, 'm/d/yyyy "at" h:MM TT')}
                </span>
              </div>
              <div className="detail">
                Last used on {''}
                <span>
                  {dateFormat(connector.updatedAt, 'm/d/yyyy "at" h:MM TT')}
                </span>
              </div>
            </div>
            <div className="key">
              <span>Public Key:</span> {connector.appId}
            </div>
            <Button className="delete" color="danger" onClick={toggleDelete}>
              Delete Connector
            </Button>
          </div>
        ) : (
          <div className="no-connectors">
            Create your first connector using the button on the left
          </div>
        )}
      </div>
    );
  }
}
