import type { Component } from 'src/types/Component';
import type { MetricData } from 'src/types/MetricData';
import type { Offering, OfferingData } from 'src/types/Offering';

import { success } from 'react-notification-system-redux';
import { Action, ActionCreator } from 'redux';

import type { AppDispatch } from '../../';
import type { BaseAction } from '../types/Base';

import apiClient from '../../apiClient';

export const GET_OFFERING = 'GET_OFFERING';
export const GET_OFFERING_SUCCESS = 'GET_OFFERING_SUCCESS';
export const GET_OFFERING_FAIL = 'GET_OFFERING_FAIL';
export const GET_OFFERING_DATA = 'GET_OFFERING_DATA';
export const GET_OFFERING_DATA_SUCCESS = 'GET_OFFERING_DATA_SUCCESS';
export const GET_OFFERING_DATA_FAIL = 'GET_OFFERING_DATA_FAIL';
export const ADD_OFFERING_DATA = 'ADD_OFFERING_DATA';
export const ADD_OFFERING_DATA_FAIL = 'ADD_OFFERING_DATA_FAIL';
export const ADD_OFFERING_DATA_SUCCESS = 'ADD_OFFERING_DATA_SUCCESS';
export const SAVE_OFFERING_DATA = 'SAVE_OFFERING_DATA';
export const SAVE_OFFERING_DATA_SUCCESS = 'SAVE_OFFERING_DATA_SUCCESS';
export const SAVE_OFFERING_DATA_FAIL = 'SAVE_OFFERING_DATA_FAIL';
export const GET_COMPONENTS = 'GET_COMPONENTS';
export const GET_COMPONENTS_SUCCESS = 'GET_COMPONENTS_SUCCESS';
export const GET_COMPONENTS_FAIL = 'GET_COMPONENTS_FAIL';
export const GET_COMPONENT = 'GET_COMPONENT';
export const GET_COMPONENT_SUCCESS = 'GET_COMPONENT_SUCCESS';
export const GET_COMPONENT_FAIL = 'GET_COMPONENT_FAIL';
export const ADD_COMPONENT = 'ADD_COMPONENT';
export const ADD_COMPONENT_SUCCESS = 'ADD_COMPONENT_SUCCESS';
export const ADD_COMPONENT_FAIL = 'ADD_COMPONENT_FAIL';
export const DELETE_COMPONENT = 'DELETE_COMPONENT';
export const DELETE_COMPONENT_SUCCESS = 'DELETE_COMPONENT_SUCCESS';
export const DELETE_COMPONENT_FAIL = 'DELETE_COMPONENT_FAIL';
export const SAVE_COMPONENT = 'SAVE_COMPONENT';
export const SAVE_COMPONENT_SUCCESS = 'SAVE_COMPONENT_SUCCESS';
export const SAVE_COMPONENT_FAIL = 'SAVE_COMPONENT_FAIL';
export const SAVE_OFFERING = 'SAVE_OFFERING';
export const SAVE_OFFERING_SUCCESS = 'SAVE_OFFERING_SUCCESS';
export const SAVE_OFFERING_FAIL = 'SAVE_OFFERING_FAIL';
export const FORCE_LOCK_COMPONENT = 'FORCE_LOCK_COMPONENT';
export const FORCE_LOCK_COMPONENT_SUCCESS = 'FORCE_LOCK_COMPONENT_SUCCESS';
export const FORCE_LOCK_COMPONENT_FAIL = 'FORCE_LOCK_COMPONENT_FAIL';
export const LOCK_COMPONENT = 'LOCK_COMPONENT';
export const LOCK_COMPONENT_SUCCESS = 'LOCK_COMPONENT_SUCCESS';
export const LOCK_COMPONENT_FAIL = 'LOCK_COMPONENT_FAIL';
export const UNLOCK_COMPONENT = 'UNLOCK_COMPONENT';
export const UNLOCK_COMPONENT_SUCCESS = 'UNLOCK_COMPONENT_SUCCESS';
export const UNLOCK_COMPONENT_FAIL = 'UNLOCK_COMPONENT_FAIL';
export const SELECT_COMPONENT = 'SELECT_COMPONENT';
export const SELECT_LOCKED_COMPONENT = 'SELECT_LOCKED_COMPONENT';
export const UPDATE_COMPONENT_DATA = 'UPDATE_COMPONENT_DATA';
export const UPDATE_OFFERING_FIELDS = 'UPDATE_OFFERING_FIELDS';
export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';
export const UPLOAD_MEDIA_SUCCESS = 'UPLOAD_MEDIA_SUCCESS';
export const UPLOAD_MEDIA_FAIL = 'UPLOAD_MEDIA_FAIL';

export const getOfferingStart: ActionCreator<Action> = () => {
  return {
    type: GET_OFFERING
  };
};

export const getOfferingSuccess: ActionCreator<
  BaseAction<Offering>
> = offering => {
  return {
    payload: offering,
    type: GET_OFFERING_SUCCESS
  };
};

export const getOfferingFail: ActionCreator<Action> = () => {
  return {
    type: GET_OFFERING_FAIL
  };
};

export const getOffering = (id: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(getOfferingStart());
    return apiClient
      .get(`/authoring/offerings/${id}`)
      .then(response => {
        dispatch(getOfferingSuccess(response.data));
        return response.data;
      })
      .catch(error => {
        dispatch(getOfferingFail());
        throw error;
      });
  };
};

export const getOfferingDataStart: ActionCreator<Action> = () => {
  return {
    type: GET_OFFERING_DATA
  };
};

export const getOfferingDataSuccess: ActionCreator<
  BaseAction<OfferingData>
> = offering => {
  return {
    payload: offering,
    type: GET_OFFERING_DATA_SUCCESS
  };
};

export const getOfferingDataFail: ActionCreator<Action> = () => {
  return {
    type: GET_OFFERING_DATA_FAIL
  };
};

export const getOfferingData = (id: number, noLoading?: boolean) => {
  return (dispatch: AppDispatch) => {
    if (!noLoading) dispatch(getOfferingDataStart());
    return apiClient
      .get(`/authoring/offerings/${id}/data`)
      .then(response => {
        dispatch(getOfferingDataSuccess(response.data));
        return response.data;
      })
      .catch(error => {
        dispatch(getOfferingDataFail());
        throw error;
      });
  };
};

export const addOfferingDataStart: ActionCreator<Action> = () => {
  return {
    type: ADD_OFFERING_DATA
  };
};

export const addOfferingDataSuccess: ActionCreator<Action> = () => {
  return {
    type: ADD_OFFERING_DATA_SUCCESS
  };
};

export const addOfferingDataFail: ActionCreator<Action> = () => {
  return {
    type: ADD_OFFERING_DATA_FAIL
  };
};

export const addOfferingData = (offeringId: number, metricData: MetricData) => {
  return (dispatch: AppDispatch) => {
    dispatch(addOfferingDataStart());
    return apiClient
      .post(`/authoring/offerings/${offeringId}/data`, metricData)
      .then(response => {
        dispatch(addOfferingDataSuccess());
        return response.data;
      })
      .catch(error => {
        dispatch(addOfferingDataFail());
        throw error;
      });
  };
};

export const saveOfferingDataStart: ActionCreator<Action> = () => {
  return {
    type: SAVE_OFFERING_DATA
  };
};

export const saveOfferingDataSuccess: ActionCreator<Action> = () => {
  return {
    type: SAVE_OFFERING_DATA_SUCCESS
  };
};

export const saveOfferingDataFail: ActionCreator<Action> = () => {
  return {
    type: SAVE_OFFERING_DATA_FAIL
  };
};

export const saveOfferingData = (
  offeringId: number,
  offeringDataId: number,
  metricData: MetricData
) => {
  return (dispatch: AppDispatch) => {
    dispatch(saveOfferingDataStart());
    return apiClient
      .put(
        `/authoring/offerings/${offeringId}/data/${offeringDataId}`,
        metricData
      )
      .then(response => {
        dispatch(saveOfferingDataSuccess());
        return response.data;
      })
      .catch(error => {
        dispatch(saveOfferingDataFail());
        throw error;
      });
  };
};

export const getComponentsStart: ActionCreator<Action> = () => {
  return {
    type: GET_COMPONENTS
  };
};

export const getComponentsSuccess: ActionCreator<
  BaseAction<Component[]>
> = components => {
  return {
    payload: components,
    type: GET_COMPONENTS_SUCCESS
  };
};

export const getComponentsFail: ActionCreator<Action> = () => {
  return {
    type: GET_COMPONENTS_FAIL
  };
};

export const getComponents = (id: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(getComponentsStart());
    return apiClient
      .get<Component[]>(`/authoring/offerings/${id}/components`)
      .then(response => {
        dispatch(getComponentsSuccess(response.data));
        return response.data;
      })
      .catch(error => {
        dispatch(getComponentsFail());
        throw error;
      });
  };
};

export const getComponentStart: ActionCreator<Action> = () => {
  return {
    type: GET_COMPONENT
  };
};

export const getComponentSuccess: ActionCreator<
  BaseAction<Component>
> = component => {
  return {
    payload: component,
    type: GET_COMPONENT_SUCCESS
  };
};

export const getComponentFail: ActionCreator<Action> = () => {
  return {
    type: GET_COMPONENT_FAIL
  };
};

export const getComponent = (offeringId: number, componentId: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(getComponentStart());
    return apiClient
      .get(`/authoring/offerings/${offeringId}/components/${componentId}`)
      .then(response => {
        dispatch(getComponentSuccess(response.data));
        return response.data;
      })
      .catch(error => {
        dispatch(getComponentFail());
        throw error;
      });
  };
};

export const addComponentStart: ActionCreator<Action> = () => {
  return {
    type: ADD_COMPONENT
  };
};

export const addComponentSuccess: ActionCreator<
  BaseAction<null, { id: number }>
> = (componentId: number) => {
  return {
    id: componentId,
    type: ADD_COMPONENT_SUCCESS
  };
};

export const addComponentFail: ActionCreator<Action> = () => {
  return {
    type: ADD_COMPONENT_FAIL
  };
};

export const addComponent = (offeringId: number, values: Component) => {
  return (dispatch: AppDispatch) => {
    dispatch(addComponentStart());
    return apiClient
      .post(`/authoring/offerings/${offeringId}/components`, values)
      .then(response => {
        dispatch(addComponentSuccess(response.data.id));
      })
      .catch(error => {
        dispatch(addComponentFail());
        throw error;
      });
  };
};

export const deleteComponentStart: ActionCreator<Action> = () => {
  return {
    type: DELETE_COMPONENT
  };
};

export const deleteComponentSuccess: ActionCreator<Action> = () => {
  return {
    type: DELETE_COMPONENT_SUCCESS
  };
};

export const deleteComponentFail: ActionCreator<Action> = () => {
  return {
    type: DELETE_COMPONENT_FAIL
  };
};

export const deleteComponent = (offeringId: number, componentId: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(deleteComponentStart());
    return apiClient
      .delete(`/authoring/offerings/${offeringId}/components/${componentId}`)
      .then(() => {
        dispatch(
          success({
            title: 'Component deleted'
          })
        );
        dispatch(deleteComponentSuccess());
      })
      .catch(error => {
        dispatch(deleteComponentFail());
        throw error;
      });
  };
};

export const saveComponentStart: ActionCreator<Action> = () => {
  return {
    type: SAVE_COMPONENT
  };
};

export const saveComponentSuccess: ActionCreator<Action> = () => {
  return {
    type: SAVE_COMPONENT_SUCCESS
  };
};

export const saveComponentFail: ActionCreator<Action> = () => {
  return {
    type: SAVE_COMPONENT_FAIL
  };
};

export const saveComponent = (
  offeringId: number,
  componentId: number,
  values: Component,
  noSuccessMessage?: boolean
) => {
  return (dispatch: AppDispatch) => {
    dispatch(saveComponentStart());
    return apiClient
      .put(
        `/authoring/offerings/${offeringId}/components/${componentId}`,
        values
      )
      .then(() => {
        if (!noSuccessMessage) {
          dispatch(
            success({
              title: 'Offering saved'
            })
          );
        }
        dispatch(saveComponentSuccess());
      })
      .catch(error => {
        dispatch(saveComponentFail());
        throw error;
      });
  };
};

export const saveOfferingStart: ActionCreator<Action> = () => {
  return {
    type: SAVE_OFFERING
  };
};

export const saveOfferingSuccess: ActionCreator<Action> = () => {
  return {
    type: SAVE_OFFERING_SUCCESS
  };
};

export const saveOfferingFail: ActionCreator<Action> = () => {
  return {
    type: SAVE_OFFERING_FAIL
  };
};

export const saveOffering = (id: number, values: Offering) => {
  return (dispatch: AppDispatch) => {
    dispatch(saveOfferingStart());
    return apiClient
      .put(`/authoring/offerings/${id}`, values.data)
      .then(() => {
        dispatch(
          success({
            title: 'Offering saved'
          })
        );
        dispatch(saveOfferingSuccess());
      })
      .catch(error => {
        dispatch(saveOfferingFail());
        throw error;
      });
  };
};

export const lockComponentStart: ActionCreator<Action> = () => {
  return {
    type: LOCK_COMPONENT
  };
};

export const lockComponentSuccess: ActionCreator<Action> = () => {
  return {
    type: LOCK_COMPONENT_SUCCESS
  };
};

export const lockComponentFail: ActionCreator<Action> = () => {
  return {
    type: LOCK_COMPONENT_FAIL
  };
};

export const lockComponent = (offeringId: number, componentId: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(lockComponentStart());
    return apiClient
      .post(`/authoring/offerings/${offeringId}/components/${componentId}/lock`)
      .then(response => {
        dispatch(lockComponentSuccess());
        return response;
      })
      .catch(error => {
        dispatch(lockComponentFail());
        throw error;
      });
  };
};

export const unlockComponentStart: ActionCreator<Action> = () => {
  return {
    type: UNLOCK_COMPONENT
  };
};

export const unlockComponentSuccess: ActionCreator<Action> = () => {
  return {
    type: UNLOCK_COMPONENT_SUCCESS
  };
};

export const unlockComponentFail: ActionCreator<Action> = () => {
  return {
    type: UNLOCK_COMPONENT_FAIL
  };
};

export const unlockComponent = (offeringId: number, componentId: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(unlockComponentStart());
    return apiClient
      .post(
        `/authoring/offerings/${offeringId}/components/${componentId}/unlock`
      )
      .then(response => {
        dispatch(unlockComponentSuccess());
        return response.data;
      })
      .catch(error => {
        dispatch(unlockComponentFail());
        throw error;
      });
  };
};

export const forceLockComponentStart: ActionCreator<Action> = () => {
  return {
    type: FORCE_LOCK_COMPONENT
  };
};

export const forceLockComponentSuccess: ActionCreator<Action> = () => {
  return {
    type: FORCE_LOCK_COMPONENT_SUCCESS
  };
};

export const forceLockComponentFail: ActionCreator<Action> = () => {
  return {
    type: FORCE_LOCK_COMPONENT_FAIL
  };
};

export const forceLockComponent = (offeringId: number, componentId: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(forceLockComponentStart());
    return apiClient
      .post(
        `/authoring/offerings/${offeringId}/components/${componentId}/lock-force`
      )
      .then(response => {
        dispatch(forceLockComponentSuccess());
        return response.data;
      })
      .catch(error => {
        dispatch(forceLockComponentFail());
        throw error;
      });
  };
};

export const uploadMediaStart: ActionCreator<Action> = () => {
  return {
    type: UPLOAD_MEDIA
  };
};

export const uploadMediaSuccess: ActionCreator<Action> = () => {
  return {
    type: UPLOAD_MEDIA_SUCCESS
  };
};

export const uploadMediaFail: ActionCreator<Action> = () => {
  return {
    type: UPLOAD_MEDIA_FAIL
  };
};

export const uploadImage = (file: File) => {
  const data = new FormData();
  data.append('media', file);
  return (dispatch: AppDispatch) => {
    dispatch(uploadMediaStart());
    return apiClient
      .post('/authoring/media/images', data)
      .then(response => {
        dispatch(uploadMediaSuccess());
        return response.data;
      })
      .catch(error => {
        dispatch(uploadMediaFail());
        throw error;
      });
  };
};

export const uploadDocument = (file: File) => {
  const data = new FormData();
  data.append('media', file);
  return (dispatch: AppDispatch) => {
    dispatch(uploadMediaStart());
    return apiClient
      .post(`/authoring/media/documents`, data)
      .then(response => {
        dispatch(uploadMediaSuccess());
        return response.data;
      })
      .catch(error => {
        dispatch(uploadMediaFail());
        throw error;
      });
  };
};

export const selectComponent: ActionCreator<
  BaseAction<null, { id: number }>
> = (id: number) => {
  return {
    id,
    type: SELECT_COMPONENT
  };
};

export const selectLockedComponent: ActionCreator<
  BaseAction<null, { id: number }>
> = (id: number) => {
  return {
    id,
    type: SELECT_LOCKED_COMPONENT
  };
};

export const updateComponentData: ActionCreator<BaseAction<null, Component>> = (
  id: number,
  values: Component
) => {
  const { collapse, data, title } = values;
  return {
    collapse,
    data,
    id,
    title,
    type: UPDATE_COMPONENT_DATA
  };
};

export const updateOfferingFields: ActionCreator<BaseAction<null, Offering>> = (
  values: OfferingData
) => {
  return {
    data: values,
    type: UPDATE_OFFERING_FIELDS
  };
};
