import './InspectorKeyMetrics.scss';

import type { Component, ComponentMetricData } from 'src/types/Component';
import type { MetricData } from 'src/types/MetricData';
import type { OfferingData } from 'src/types/Offering';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Tooltip } from 'reactstrap';

import { midPoint } from '../../../../helpers';
import DeleteConfirmationModal from '../shared/DeleteConfirmationModal';
import KeyMetricsModal from '../shared/KeyMetricsModal';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  handleAddOfferingData: (data: ComponentMetricData) => Promise<void>;
  handleComponentLockStatus: (id: number) => Promise<boolean>;
  handleGetOfferingData: (noLoading: true) => void;
  handleLockComponent: (id: number) => void;
  handleSaveOfferingData: (id: number, data: MetricData) => Promise<void>;
  offeringData: OfferingData[];
  onChange: (component: Component, values: Component) => void;
  submitting: boolean;
};
type State = {
  addModalOpen?: boolean;
  deleteModalOpen?: boolean;
  metrics?: MetricData[];
  metricSelected?: string;
  openConnectorTooltips?: string[];
};
export default class InspectorKeyMetrics extends React.Component<Props, State> {
  state: State = {
    addModalOpen: false,
    deleteModalOpen: false,
    metrics: [],
    metricSelected: null,
    openConnectorTooltips: []
  };

  componentDidMount() {
    this.setMetrics();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.editingComponentData !== this.props.editingComponentData ||
      prevProps.componentData !== this.props.componentData
    ) {
      this.setState({ addModalOpen: false });
      this.setMetrics();
    }
  }

  connectorTooltipHover = connectorId => {
    const { openConnectorTooltips } = this.state;
    let newConnectorArray = openConnectorTooltips;
    if (openConnectorTooltips.includes(connectorId))
      newConnectorArray = openConnectorTooltips.filter(
        connector => connector !== connectorId
      );
    else newConnectorArray.push(connectorId);
    this.setState({
      openConnectorTooltips: newConnectorArray
    });
  };

  deleteMetric = metricKey => {
    const { componentData, onChange } = this.props;
    const { metrics } = this.state;
    const newMetrics = metrics.filter(metric => metric.key !== metricKey);
    const values: Component = {
      data: {
        omcms: {
          offeringData: newMetrics
        }
      }
    };
    onChange(componentData, values);
    this.toggleDeleteModal();
  };

  onDragEnd = result => {
    const { destination, draggableId, source } = result;

    // return if invalid drop destination or dropped position not changed
    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const { componentData, editingComponentData, onChange } = this.props;
    const { data } = editingComponentData || componentData;

    const metrics = data.omcms.offeringData.filter(
      (metric, index) => draggableId !== `${metric.key}-${index}`
    );

    // using closest sibling's pos as start and end boundries for dropped node
    const nodeIndex = destination.index;
    const startPos = metrics[nodeIndex - 1] && metrics[nodeIndex - 1].pos;
    const endPos = metrics[nodeIndex] && metrics[nodeIndex].pos;
    const newPos = midPoint(startPos, endPos);

    const values = {
      data: {
        omcms: {
          offeringData: data.omcms.offeringData.map((metric, index) => {
            if (draggableId === `${metric.key}-${index}`) {
              metric.pos = newPos;
            }
            return metric;
          })
        }
      }
    };

    onChange(componentData, values);
  };

  onDragStart = () => {
    const { componentData, editingComponentData, handleComponentLockStatus } =
      this.props;
    if (editingComponentData) {
      return;
    }
    handleComponentLockStatus(componentData.id);
  };

  render() {
    const {
      componentData,
      editingComponentData,
      handleAddOfferingData,
      handleGetOfferingData,
      handleSaveOfferingData,
      offeringData,
      onChange,
      submitting
    } = this.props;
    const {
      addModalOpen,
      deleteModalOpen,
      metrics,
      metricSelected,
      openConnectorTooltips
    } = this.state;

    return (
      <div>
        <button
          className="add-button"
          onClick={this.toggleAddModal}
          title="Add Metric"
          type="button"
        >
          <FontAwesomeIcon icon="plus" />
        </button>
        <DragDropContext
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
        >
          <Droppable droppableId="droppable">
            {provided => (
              <div
                className="inspector-fields"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {metrics.length > 0 ? (
                  metrics.map((metric, index) =>
                    offeringData?.map(
                      data =>
                        data.key === metric.key && (
                          <Draggable
                            draggableId={`${metric.key}-${index}`}
                            index={index}
                            key={metric.key}
                          >
                            {(draggableProvided, item) => (
                              <div
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                className={`selectable-list-item${
                                  item.isDragging ||
                                  (metricSelected &&
                                    metricSelected === metric.key)
                                    ? ' active'
                                    : ''
                                }`}
                                data-testid={`metric${index}`}
                                key={metric.key}
                                ref={draggableProvided.innerRef}
                              >
                                <FontAwesomeIcon
                                  className="component-icon"
                                  icon="percent"
                                />
                                <span className="label">{data.label}</span>
                                {data.connectorId && (
                                  <div className="connector-info">
                                    <FontAwesomeIcon
                                      className="connector-icon component-icon"
                                      data-testid={`metricConnectorTrigger${index}`}
                                      icon="cloud"
                                      id={`trigger-${metric.key}`}
                                    />
                                    <Tooltip
                                      isOpen={openConnectorTooltips.includes(
                                        `trigger-${metric.key}`
                                      )}
                                      placement="bottom"
                                      target={`trigger-${metric.key}`}
                                      toggle={() =>
                                        this.connectorTooltipHover(
                                          `trigger-${metric.key}`
                                        )
                                      }
                                      trigger="hover"
                                    >
                                      {data.key}
                                    </Tooltip>
                                  </div>
                                )}
                                <button
                                  className="edit"
                                  data-testid={`editMetric${index}`}
                                  onClick={_ =>
                                    this.toggleAddModal(_, data.key)
                                  }
                                  type="button"
                                >
                                  <FontAwesomeIcon
                                    className="component-icon"
                                    icon="pencil-alt"
                                  />
                                </button>
                                <button
                                  className="delete"
                                  data-testid={`deleteMetric${index}`}
                                  onClick={_ =>
                                    this.toggleDeleteModal(_, data.key)
                                  }
                                  type="button"
                                >
                                  ×
                                </button>
                              </div>
                            )}
                          </Draggable>
                        )
                    )
                  )
                ) : (
                  <p className="gray-med no-data">There are no key metrics</p>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <KeyMetricsModal
          componentData={componentData}
          editingComponentData={editingComponentData}
          handleAddOfferingData={handleAddOfferingData}
          handleGetOfferingData={handleGetOfferingData}
          handleSaveOfferingData={handleSaveOfferingData}
          isOpen={addModalOpen}
          metricSelected={metricSelected}
          offeringData={offeringData}
          onChange={onChange}
          submitting={submitting}
          toggle={this.toggleAddModal}
        />
        <DeleteConfirmationModal
          handleDelete={() => this.deleteMetric(metricSelected)}
          isOpen={deleteModalOpen}
          toggle={this.toggleDeleteModal}
          type="metric"
        />
      </div>
    );
  }

  setMetrics = () => {
    const { componentData, editingComponentData } = this.props;
    let metrics = [];
    const componentDataHasOfferingData =
      componentData?.data?.omcms?.offeringData?.length > 0;
    const editingComponentDataHasOfferingData =
      editingComponentData?.data?.omcms?.offeringData;
    if (componentDataHasOfferingData)
      metrics = componentData.data.omcms.offeringData;
    if (editingComponentDataHasOfferingData)
      metrics = editingComponentData.data.omcms.offeringData;

    // sorting metrics in ascending order of pos
    metrics.sort((a, b) => {
      if (a.pos < b.pos) {
        return -1;
      }
      if (a.pos > b.pos) {
        return 1;
      }
      return 0;
    });
    this.setState({ metrics: metrics });
  };

  toggleAddModal = async (_?, metricId?: string) => {
    const {
      componentData,
      editingComponentData,
      handleComponentLockStatus,
      handleLockComponent
    } = this.props;

    if ((!this.state.addModalOpen || metricId) && !editingComponentData) {
      const isLocked = await handleComponentLockStatus(componentData.id);
      if (isLocked) {
        return;
      }
      handleLockComponent(componentData.id);
    }

    const newState: State = {
      addModalOpen: !this.state.addModalOpen
    };
    if (!this.state.addModalOpen) newState.metricSelected = metricId;
    else newState.metricSelected = null;
    this.setState(newState);
  };

  toggleDeleteModal = async (_?, metricId?: string) => {
    const {
      componentData,
      editingComponentData,
      handleComponentLockStatus,
      handleLockComponent
    } = this.props;

    if (metricId && !editingComponentData) {
      const isLocked = await handleComponentLockStatus(componentData.id);
      if (isLocked) {
        return;
      }
      handleLockComponent(componentData.id);
    }

    const newState: State = {
      deleteModalOpen: !this.state.deleteModalOpen
    };
    if (!this.state.deleteModalOpen) newState.metricSelected = metricId;
    else newState.metricSelected = null;
    this.setState(newState);
  };
}
