import type { AppDispatch } from 'src';
import type { Offering } from 'src/types/Offering';

import { success } from 'react-notification-system-redux';
import { Action, ActionCreator } from 'redux';

import apiClient from '../../apiClient';
import { BaseAction } from '../types/Base';

export const GET_OFFERINGS = 'GET_OFFERINGS';
export const GET_OFFERINGS_SUCCESS = 'GET_OFFERINGS_SUCCESS';
export const GET_OFFERINGS_FAIL = 'GET_OFFERINGS_FAIL';
export const ADD_OFFERING = 'ADD_OFFERING';
export const ADD_OFFERING_SUCCESS = 'ADD_OFFERING_SUCCESS';
export const ADD_OFFERING_FAIL = 'ADD_OFFERING_FAIL';
export const PUBLISH_OFFERING = 'PUBLISH_OFFERING';
export const PUBLISH_OFFERING_SUCCESS = 'PUBLISH_OFFERING_SUCCESS';
export const PUBLISH_OFFERING_FAIL = 'PUBLISH_OFFERING_FAIL';
export const UNPUBLISH_OFFERING = 'UNPUBLISH_OFFERING';
export const UNPUBLISH_OFFERING_SUCCESS = 'UNPUBLISH_OFFERING_SUCCESS';
export const UNPUBLISH_OFFERING_FAIL = 'UNPUBLISH_OFFERING_FAIL';
export const ARCHIVE_OFFERING = 'ARCHIVE_OFFERING';
export const ARCHIVE_OFFERING_SUCCESS = 'ARCHIVE_OFFERING_SUCCESS';
export const ARCHIVE_OFFERING_FAIL = 'ARCHIVE_OFFERING_FAIL';
export const UNARCHIVE_OFFERING = 'UNARCHIVE_OFFERING';
export const UNARCHIVE_OFFERING_SUCCESS = 'UNARCHIVE_OFFERING_SUCCESS';
export const UNARCHIVE_OFFERING_FAIL = 'UNARCHIVE_OFFERING_FAIL';
export const CLONE_OFFERING = 'CLONE_OFFERING';
export const CLONE_OFFERING_SUCCESS = 'CLONE_OFFERING_SUCCESS';
export const CLONE_OFFERING_FAIL = 'CLONE_OFFERING_FAIL';

export const getOfferingsStart: ActionCreator<Action> = () => {
  return {
    type: GET_OFFERINGS
  };
};

export const getOfferingsSuccess: ActionCreator<BaseAction<Offering[]>> = (
  offerings: Offering[]
) => {
  return {
    payload: offerings,
    type: GET_OFFERINGS_SUCCESS
  };
};

export const getOfferingsFail: ActionCreator<Action> = () => {
  return {
    type: GET_OFFERINGS_FAIL
  };
};

export const getOfferings = () => {
  return (dispatch: AppDispatch) => {
    dispatch(getOfferingsStart());
    return apiClient
      .get('/authoring/offerings')
      .then(response => {
        dispatch(getOfferingsSuccess(response.data));
      })
      .catch(error => {
        dispatch(getOfferingsFail());
        throw error;
      });
  };
};

export const addOfferingStart: ActionCreator<Action> = () => {
  return {
    type: ADD_OFFERING
  };
};

export const addOfferingSuccess: ActionCreator<
  BaseAction<Offering>
> = values => {
  return {
    payload: values,
    type: ADD_OFFERING_SUCCESS
  };
};

export const addOfferingFail: ActionCreator<BaseAction> = error => {
  let message = 'There was an issue connecting to the server.';
  if (error?.response) message = error.response?.data?.message;
  return {
    error: message,
    type: ADD_OFFERING_FAIL
  };
};

export const addOffering = (values: Offering) => {
  return (dispatch: AppDispatch) => {
    dispatch(addOfferingStart());
    return apiClient
      .post('/authoring/offerings', values)
      .then(response => {
        dispatch(
          success({
            title: 'Offering created'
          })
        );
        dispatch(addOfferingSuccess(response.data));
        return response.data;
      })
      .catch(error => {
        dispatch(addOfferingFail(error));
        throw error;
      });
  };
};

export const publishOfferingStart: ActionCreator<Action> = () => {
  return {
    type: PUBLISH_OFFERING
  };
};

export const publishOfferingSuccess: ActionCreator<Action> = () => {
  return {
    type: PUBLISH_OFFERING_SUCCESS
  };
};

export const publishOfferingFail: ActionCreator<Action> = () => {
  return {
    type: PUBLISH_OFFERING_FAIL
  };
};

export const publishOffering = (id: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(publishOfferingStart());
    return apiClient
      .post(`/authoring/offerings/${id}/publish`)
      .then(() => {
        dispatch(
          success({
            title: 'Offering marked as completed'
          })
        );
        dispatch(publishOfferingSuccess());
      })
      .catch(error => {
        dispatch(publishOfferingFail());
        throw error;
      });
  };
};

export const unpublishOfferingStart: ActionCreator<Action> = () => {
  return {
    type: UNPUBLISH_OFFERING
  };
};

export const unpublishOfferingSuccess: ActionCreator<Action> = () => {
  return {
    type: UNPUBLISH_OFFERING_SUCCESS
  };
};

export const unpublishOfferingFail: ActionCreator<Action> = () => {
  return {
    type: UNPUBLISH_OFFERING_FAIL
  };
};

export const unpublishOffering = (id: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(unpublishOfferingStart());
    return apiClient
      .post(`/authoring/offerings/${id}/unpublish`)
      .then(() => {
        dispatch(
          success({
            title: 'Offering marked as draft'
          })
        );
        dispatch(unpublishOfferingSuccess());
      })
      .catch(error => {
        dispatch(unpublishOfferingFail());
        throw error;
      });
  };
};

export const archiveOfferingStart: ActionCreator<Action> = () => {
  return {
    type: ARCHIVE_OFFERING
  };
};

export const archiveOfferingSuccess: ActionCreator<Action> = () => {
  return {
    type: ARCHIVE_OFFERING_SUCCESS
  };
};

export const archiveOfferingFail: ActionCreator<Action> = () => {
  return {
    type: ARCHIVE_OFFERING_FAIL
  };
};

export const archiveOffering = (id: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(archiveOfferingStart());
    return apiClient
      .post(`/authoring/offerings/${id}/archive`)
      .then(() => {
        dispatch(
          success({
            title: 'Offering archived'
          })
        );
        dispatch(archiveOfferingSuccess());
      })
      .catch(error => {
        dispatch(archiveOfferingFail());
        throw error;
      });
  };
};

export const unarchiveOfferingStart: ActionCreator<Action> = () => {
  return {
    type: UNARCHIVE_OFFERING
  };
};

export const unarchiveOfferingSuccess: ActionCreator<Action> = () => {
  return {
    type: UNARCHIVE_OFFERING_SUCCESS
  };
};

export const unarchiveOfferingFail: ActionCreator<Action> = () => {
  return {
    type: UNARCHIVE_OFFERING_FAIL
  };
};

export const unarchiveOffering = (id: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(unarchiveOfferingStart());
    return apiClient
      .post(`/authoring/offerings/${id}/unarchive`)
      .then(() => {
        dispatch(
          success({
            title: 'Offering un-archived'
          })
        );
        dispatch(unarchiveOfferingSuccess());
      })
      .catch(error => {
        dispatch(unarchiveOfferingFail());
        throw error;
      });
  };
};

export const cloneOfferingStart: ActionCreator<Action> = () => {
  return {
    type: CLONE_OFFERING
  };
};

export const cloneOfferingSuccess: ActionCreator<Action> = () => {
  return {
    type: CLONE_OFFERING_SUCCESS
  };
};

export const cloneOfferingFail: ActionCreator<Action> = () => {
  return {
    type: CLONE_OFFERING_FAIL
  };
};

export const cloneOffering = (id: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(cloneOfferingStart());
    return apiClient
      .post(`/authoring/offerings/${id}/clone`)
      .then(() => {
        dispatch(
          success({
            title: 'Offering cloned'
          })
        );
        dispatch(cloneOfferingSuccess());
      })
      .catch(error => {
        dispatch(cloneOfferingFail());
        throw error;
      });
  };
};
