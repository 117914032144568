import './Login.scss';

import type { AppDispatch, RootState } from 'src';

import React from 'react';
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline
} from 'react-google-login';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Container } from 'reactstrap';

import withRouter from '../../components/withRouter/withRouter';
import { googleClientId } from '../../constants';
import { submitGoogleLogin, submitLogin } from '../../redux/actions/Auth';
import GoogleLogo from './components/GoogleLogo';
import LoginForm from './components/LoginForm';

type Props = {
  dispatch: AppDispatch;
  error: string;
  submitting: boolean;
};

class Login extends React.Component<Props> {
  componentDidMount() {
    document.title = 'Login';
  }

  handleGoogleLoginFailure = (errorCode: string, errorDescription: string) => {
    console.log(
      `Google login failed with code ${errorCode}: ${errorDescription}`
    );
  };

  handleGoogleLoginSuccess = (
    googleResponse: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    const { dispatch } = this.props;
    const idToken = (googleResponse as GoogleLoginResponse)?.tokenObj?.id_token;

    if (idToken == null) {
      console.log(`Google ID token unexpectedly empty: ${googleResponse}`);
    }
    dispatch(submitGoogleLogin(idToken));
  };

  handleSubmit = (email: string, password: string) => {
    const { dispatch } = this.props;
    dispatch(submitLogin(email, password));
  };

  render() {
    const { error, submitting } = this.props;
    const hideSignupLink =
      process.env.REACT_APP_HIDE_REGISTRATION_LINK === 'true';

    return (
      <Container
        className="container-narrow flex-vertical-center no-header-padding"
        data-testid="login"
        id="login"
      >
        <Card>
          <LoginForm
            error={error}
            handleSubmit={this.handleSubmit}
            submitting={submitting}
          />
          {!hideSignupLink && (
            <p>
              Don't have an account? <Link to="/signup/">Sign Up</Link>
            </p>
          )}
          <div className="google-login">
            <p>Login with Google</p>
            <GoogleLogin
              buttonText="Login with Google"
              clientId={googleClientId}
              cookiePolicy="single_host_origin"
              hostedDomain="equitymultiple.com"
              // @ts-expect-error Types for this function are wrong, see https://github.com/anthonyjgrove/react-google-login/issues/437
              onFailure={this.handleGoogleLoginFailure}
              onSuccess={this.handleGoogleLoginSuccess}
              render={renderProps => (
                <button
                  className="google-login-button"
                  disabled={renderProps.disabled}
                  onClick={renderProps.onClick}
                  type="button"
                >
                  <GoogleLogo />
                </button>
              )}
            />
          </div>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = (store: RootState) => {
  return {
    error: store.Auth.error,
    submitting: store.Auth.submitting
  };
};

export default withRouter(connect(mapStateToProps)(Login));
