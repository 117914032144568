import './CanvasText.scss';

import type { Component } from 'src/types/Component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Value } from 'slate';
import { Editor, getEventTransfer } from 'slate-react';

import { debounce } from '../../../../helpers';
import {
  initialSlateTextContent,
  slateTextHtmlSerializer,
  slateTextSchema
} from '../helpers/slate';
import Footnotes from '../shared/Footnotes';

type Props = {
  active: boolean;
  componentData: Component;
  editingComponentData: Component;
  handleComponentLockStatus: (id: number) => Promise<boolean>;
  handleLockComponent: (id: number) => void;
  onChange: (component: Component, values: Component) => void;
};

export default class CanvasText extends React.Component<Props> {
  componentWrap = React.createRef<HTMLDivElement>();

  // TODO: current Slate version does not support TS, casting as any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editor = React.createRef() as any;

  handleChange = debounce(value => {
    const { componentData, onChange } = this.props;

    const values = {
      data: {
        editorData: {
          content: value.toJSON(),
          output: slateTextHtmlSerializer.serialize(value)
        }
      }
    };

    onChange(componentData, values);
  }, 100);

  initialValue = Value.fromJSON(
    this.props.componentData.data.editorData.content.document
      ? this.props.componentData.data.editorData.content
      : initialSlateTextContent
  );

  state = {
    fixedToolbar: false,
    value: this.initialValue
  };

  componentDidUpdate(prevProps) {
    const { active } = this.props;
    if (!prevProps.active && active) {
      this.handleScroll();
      window.addEventListener('scroll', this.handleScroll);
    }
    if (prevProps.active && !active)
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleClickBlock = (e, type) => {
    e.preventDefault();

    const editor = this.editor.current;
    const { value } = editor;
    const { document } = value;

    // Handle everything but list buttons
    if (type !== 'bulleted-list' && type !== 'numbered-list') {
      const isActive = this.hasBlock(type);
      const isList = this.hasBlock('list-item');

      if (isList) {
        editor
          .setBlocks(isActive ? 'paragraph' : type)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else if (type === 'tab') {
        editor.insertInline({
          nodes: [],
          object: 'inline',
          type: 'tab'
        });
      } else {
        editor.setBlocks(isActive ? 'paragraph' : type);
      }
    } else {
      // Handle the extra wrapping required for list buttons
      const isList = this.hasBlock('list-item');
      const isType = value.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type === type);
      });

      if (isList && isType) {
        editor
          .setBlocks('paragraph')
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else if (isList) {
        editor
          .unwrapBlock(
            type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list'
          )
          .wrapBlock(type);
      } else {
        editor.setBlocks('list-item').wrapBlock(type);
      }
    }
  };

  handleClickLink = e => {
    e.preventDefault();

    const editor = this.editor.current;
    const { value } = editor;

    if (this.hasLinks()) {
      editor.command(this.unwrapLink);
    } else if (value.selection.isExpanded) {
      const href = window.prompt('Enter the URL of the link:');
      if (href == null) return;

      editor.command(this.wrapLink, href);
    } else {
      const href = window.prompt('Enter the URL of the link:');
      if (href == null) return;

      const text = window.prompt('Enter the text for the link:');

      if (text == null) return;

      editor
        .insertText(text)
        .moveFocusBackward(text.length)
        .command(this.wrapLink, href);
    }
  };

  handleClickMark = (e, type) => {
    e.preventDefault();
    this.editor.current.toggleMark(type);
  };

  handlePaste = (event, editor, next) => {
    const transfer = getEventTransfer(event);
    if (transfer.type !== 'html') return next();
    const { document } = slateTextHtmlSerializer.deserialize(transfer.html);
    editor.insertFragment(document);
  };

  handleScroll = () => {
    const { fixedToolbar } = this.state;
    const offsetTop = this.componentWrap.current
      ? (this.componentWrap.current as Element).getBoundingClientRect().top
      : null;
    const offsetBottom = this.componentWrap.current
      ? (this.componentWrap.current as Element).getBoundingClientRect().bottom
      : null;
    const navHeight = 56;
    const editorInView = offsetTop < navHeight && offsetBottom > 200;
    if (!fixedToolbar && editorInView) this.setState({ fixedToolbar: true });
    if (fixedToolbar && !editorInView) this.setState({ fixedToolbar: false });
  };

  hasBlock = type => this.state.value.blocks.some(node => node.type === type);

  hasLinks = () =>
    this.state.value.inlines.some(inline => inline.type === 'link');

  hasMark = type =>
    this.state.value.activeMarks.some(mark => mark.type === type);

  render() {
    const {
      active,
      componentData,
      editingComponentData,
      handleComponentLockStatus,
      handleLockComponent,
      onChange
    } = this.props;
    const { fixedToolbar } = this.state;

    return (
      <div className={`overflow-hidden`}>
        <div
          className={`${fixedToolbar ? 'fixed-toolbar' : ''}`}
          ref={this.componentWrap}
        >
          {active && (
            <div className="editor-toolbar" data-testid="editorToolbar">
              {this.renderMarkButton('bold', 'bold')}
              {this.renderMarkButton('italic', 'italic')}
              {this.renderMarkButton('underline', 'underline')}
              {this.renderMarkButton('strikethrough', 'strikethrough')}
              {this.renderMarkButton('superscript', 'superscript')}
              <button
                className={`toggle-button ${this.hasLinks() ? 'active' : ''}`}
                onMouseDown={this.handleClickLink}
                title="hyperlink"
                type="button"
              >
                <FontAwesomeIcon icon="link" />
              </button>
              {this.renderBlockButton('quote', 'quote-left')}
              {this.renderBlockButton('numbered-list', 'list-ol')}
              {this.renderBlockButton('bulleted-list', 'list-ul')}
              {this.renderBlockButton('tab', 'long-arrow-alt-right')}
            </div>
          )}
          <Editor
            autoFocus={active} // eslint-disable-line jsx-a11y/no-autofocus
            id={`editor-${componentData.id.toString()}`}
            onChange={({ value }) => {
              if (value.document !== this.state.value.document)
                this.handleChange(value);
              this.setState({ value });
            }}
            onPaste={this.handlePaste}
            placeholder="Add content here"
            readOnly={!active}
            ref={this.editor}
            renderBlock={this.renderBlock}
            renderInline={this.renderInline}
            renderMark={this.renderMark}
            schema={slateTextSchema}
            value={this.state.value}
          />
        </div>
        <Footnotes
          active={active}
          currentData={
            editingComponentData && editingComponentData.id === componentData.id
              ? editingComponentData
              : componentData
          }
          editingComponentData={editingComponentData}
          handleComponentLockStatus={handleComponentLockStatus}
          handleLockComponent={handleLockComponent}
          onChange={onChange}
        />
      </div>
    );
  }

  renderBlock = (props, _, next) => {
    const { attributes, children, node } = props;
    const className = node.data.get('className');
    if (className) attributes.className = className;

    switch (node.type) {
      case 'paragraph':
        return <p {...attributes}>{children}</p>;
      case 'quote':
        return <blockquote {...attributes}>{children}</blockquote>;
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>;
      case 'list-item':
        return <li {...attributes}>{children}</li>;
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>;
      default:
        return next();
    }
  };

  renderBlockButton = (type, icon) => {
    let active = this.hasBlock(type);

    if (['bulleted-list', 'numbered-list'].includes(type)) {
      const {
        value: { blocks, document }
      } = this.state;

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key);
        active = this.hasBlock('list-item') && parent && parent.type === type;
      }
    }

    return (
      <button
        className={`toggle-button button-${type} ${active ? 'active' : ''}`}
        onMouseDown={event => this.handleClickBlock(event, type)}
        title={type}
        type="button"
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    );
  };

  renderInline = (props, _, next) => {
    const { attributes, children, node } = props;

    switch (node.type) {
      case 'link':
        return (
          <a
            href={node.data?.get('href')}
            rel="noopener noreferrer"
            target="_blank"
            {...attributes}
          >
            {children}
          </a>
        );
      case 'tab':
        return <span {...attributes}>&emsp;{children}</span>;
      default:
        return next();
    }
  };

  renderMark = (props, _, next) => {
    const { attributes, children, mark } = props;

    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>;
      case 'italic':
        return <em {...attributes}>{children}</em>;
      case 'underline':
        return <u {...attributes}>{children}</u>;
      case 'strikethrough':
        return <s {...attributes}>{children}</s>;
      case 'superscript':
        return <sup {...attributes}>{children}</sup>;
      default:
        return next();
    }
  };

  renderMarkButton = (type, icon) => (
    <button
      className={`toggle-button ${this.hasMark(type) ? 'active' : ''}`}
      onMouseDown={event => this.handleClickMark(event, type)}
      title={type}
      type="button"
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  );

  unwrapLink = editor => {
    editor.unwrapInline('link');
  };

  wrapLink = (editor, href) => {
    editor.wrapInline({
      data: { href },
      type: 'link'
    });
    editor.moveToEnd();
  };
}
