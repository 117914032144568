import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import * as Yup from 'yup';

import ButtonPlus from '../../../components/ButtonPlus/ButtonPlus';
import { offeringName } from '../../../regex';

type Props = {
  error: string;
  handleSubmit: (values: { name: string }) => void;
  isOpen: boolean;
  submitting: boolean;
  toggle: () => void;
};

export default class CreateOfferingModal extends React.Component<Props> {
  offeringSchema = Yup.object().shape({
    name: Yup.string()
      .required('Offering name is required')
      .min(3, 'Offering name must be at least 3 characters')
      .max(128, 'Offering name must be less than 128 characters')
      .matches(offeringName.pattern, {
        excludeEmptyString: true,
        message: `Offering ${offeringName.description}`
      })
  });

  render() {
    const { error, handleSubmit, isOpen, submitting, toggle } = this.props;

    return (
      <Modal
        centered
        id="create-offering-modal"
        isOpen={isOpen}
        toggle={toggle}
      >
        <Formik
          initialValues={{
            name: ''
          }}
          onSubmit={values => handleSubmit(values)}
          validationSchema={this.offeringSchema}
        >
          <Form placeholder={undefined}>
            <ModalHeader toggle={toggle}>New Offering</ModalHeader>
            <ModalBody>
              <label htmlFor="name">Offering Name</label>
              <Input id="name" name="name" tag={Field} type="text" />
              <ErrorMessage name="name" render={this.renderError} />
              {error && this.renderError(error)}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <ButtonPlus
                color="primary"
                data-testid="createSubmitButton"
                loading={submitting}
                style={{ width: '190px' }}
                type="submit"
              >
                Create Offering
              </ButtonPlus>
            </ModalFooter>
          </Form>
        </Formik>
      </Modal>
    );
  }

  renderError = (message: string) => <Alert color="danger">{message}</Alert>;
}
