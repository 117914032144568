import './ConnectorList.scss';

import type { Connector } from 'src/types/Connector';

import dateFormat from 'dateformat';
import React from 'react';
import { Button } from 'reactstrap';

type Props = {
  active: number;
  connectors: Connector[];
  setActive: (active: number) => void;
  toggle: () => void;
};

export default class ConnectorList extends React.Component<Props> {
  render() {
    const { active, connectors, setActive, toggle } = this.props;

    return (
      <div data-testid="connectorList" id="connector-list">
        <Button color="primary" onClick={toggle}>
          Add Connector
        </Button>
        {connectors &&
          connectors.map(connector => (
            <button
              className={`item ${
                connectors.indexOf(connector) === active ? 'active' : ''
              }`}
              key={connector.id}
              onClick={() => setActive(connectors.indexOf(connector))}
              type="button"
            >
              <div className="title">{connector.name}</div>
              <div className="date">
                created on{' '}
                {dateFormat(connector.createdAt, 'm/d/yyyy "at" h:MM TT')}
              </div>
            </button>
          ))}
      </div>
    );
  }
}
