import 'react-datepicker/dist/react-datepicker.css';

import './InspectorCashFlow.scss';

import type { Component, ComponentData } from 'src/types/Component';

import { Field, Form, Formik } from 'formik';
import React from 'react';
import DatePicker from 'react-datepicker';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  onChange: (component: Component, values: Component) => void;
  submitting: boolean;
};
type State = ComponentData & {
  distributionCount?: number;
  distributions?: Record<string, unknown>[];
  firstDistributionDate?: string;
  frequency?: string;
  loadCumulative?: boolean;
};
export default class InspectorCashFlow extends React.Component<Props, State> {
  state: State = {
    distributionCount: 0,
    distributions: [],
    firstDistributionDate: new Date().toString(),
    frequency: 'Monthly',
    loadCumulative: false
  };

  componentDidMount() {
    this.setCashFlow();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.componentData.id !== this.props.componentData.id ||
      prevProps.editingComponentData !== this.props.editingComponentData
    ) {
      this.setCashFlow();
    }
  }

  handleChange = data => {
    const { componentData, onChange } = this.props;
    const values = {
      data: {
        ...this.state,
        ...data
      }
    };

    onChange(componentData, values);
  };

  render() {
    return (
      <div className="inspector-fields">
        <Formik
          enableReinitialize
          initialValues={{ ...this.state }}
          onSubmit={this.handleChange}
          render={({ submitForm }) => (
            <Form placeholder={undefined}>
              <label htmlFor="distributionCount">Number of Distributions</label>
              <Field
                name="distributionCount"
                render={({ field, form }) => (
                  <input
                    {...field}
                    className="form-control"
                    id="distributionCount"
                    min="0"
                    onChange={e => {
                      form.setFieldValue('distributionCount', e.target.value);
                      setTimeout(() => {
                        submitForm();
                      });
                    }}
                    type="number"
                  />
                )}
              />
              <hr />
              <h6>Chart Settings</h6>
              <label htmlFor="frequency">Frequency</label>
              <Field
                name="frequency"
                render={({ field, form }) => (
                  <select
                    {...field}
                    className="form-control"
                    id="frequency"
                    onChange={e => {
                      form.setFieldValue('frequency', e.target.value);
                      setTimeout(() => {
                        submitForm();
                      });
                    }}
                    value={field.value}
                  >
                    <option value="Monthly">Monthly</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Annual">Annual</option>
                  </select>
                )}
              />
              <label htmlFor="firstDistributionDate">
                First Distribution Date
              </label>
              <Field
                name="firstDistributionDate"
                render={({ field, form }) => (
                  <DatePicker
                    {...field}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    id="firstDistributionDate"
                    onChange={date => {
                      form.setFieldValue(
                        'firstDistributionDate',
                        new Date(date).toString()
                      );
                      setTimeout(() => {
                        submitForm();
                      });
                    }}
                    selected={new Date(field.value)}
                  />
                )}
              />
              <div className="checkbox-group">
                <Field
                  name="loadCumulative"
                  render={({ field, form }) => (
                    <input
                      {...field}
                      checked={field.value}
                      id="loadCumulative"
                      onChange={e => {
                        form.setFieldValue('loadCumulative', e.target.checked);
                        setTimeout(() => {
                          submitForm();
                        });
                      }}
                      type="checkbox"
                    />
                  )}
                />
                <label htmlFor="loadCumulative">Load as Cumulative</label>
              </div>
            </Form>
          )}
        />
      </div>
    );
  }

  setCashFlow = () => {
    const { componentData, editingComponentData } = this.props;
    let state: State = {};
    if (componentData && componentData.data) {
      state = componentData.data;
    }
    if (
      editingComponentData &&
      editingComponentData.id === componentData.id &&
      editingComponentData.data
    ) {
      state = editingComponentData.data;
    }
    state.firstDistributionDate =
      state.firstDistributionDate || new Date().toString();
    this.setState(state);
  };
}
