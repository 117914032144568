import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import ButtonPlus from '../../../components/ButtonPlus/ButtonPlus';

type Props = {
  deleteComponent: () => void;
  hasChild: boolean;
  hasLockedChild: boolean;
  isOpen: boolean;
  submitting: boolean;
  toggle: (id?: number) => void;
};

export default class DeleteComponentModal extends React.Component<Props> {
  render() {
    const {
      deleteComponent,
      hasChild,
      hasLockedChild,
      isOpen,
      submitting,
      toggle
    } = this.props;

    return (
      <Modal
        centered
        data-testid="deleteComponentModal"
        isOpen={isOpen}
        toggle={() => toggle()}
      >
        <ModalHeader toggle={() => toggle()}>Delete Component</ModalHeader>
        <ModalBody>
          {hasChild || hasLockedChild ? (
            <div>
              {hasLockedChild ? (
                <p>
                  This section has a child component which is currently being
                  edited by another user. Deleting this section will also delete
                  all of its children, including the component being edited.
                </p>
              ) : (
                <p>
                  This section has at least one child component. Deleting this
                  section will also delete all of its children.
                </p>
              )}
              <p>
                <strong>Are you sure you want to delete this section?</strong>
              </p>
            </div>
          ) : (
            <p>Are you sure you want to delete this component?</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggle()}>
            Cancel
          </Button>
          <ButtonPlus
            color="danger"
            loading={submitting}
            onClick={deleteComponent}
            style={{ width: '190px' }}
          >
            Yes, Delete It
          </ButtonPlus>
        </ModalFooter>
      </Modal>
    );
  }
}
