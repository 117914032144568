import type { Component } from 'src/types/Component';
import type { Image } from 'src/types/Image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { cloneObject } from '../../../../helpers';
import DeleteConfirmationModal from '../shared/DeleteConfirmationModal';
import FileModal from '../shared/FileModal';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  handleImageUpload: (image: File) => Promise<Image>;
  onChange: (component: Component, values: Component) => void;
  submitting: boolean;
};
type State = {
  addModalOpen: boolean;
  deleteModalOpen: boolean;
  imageDeleted: boolean;
};
export default class InspectorHero extends React.Component<Props, State> {
  state: State = {
    addModalOpen: false,
    deleteModalOpen: false,
    imageDeleted: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.editingComponentData !== this.props.editingComponentData)
      this.setState({ addModalOpen: false });
    if (
      prevProps.editingComponentData &&
      !prevProps.editingComponentData.data.omcms.images[0] &&
      this.props.editingComponentData.data.omcms.images[0]
    )
      this.setState({ imageDeleted: false });
  }

  deleteImage = () => {
    const { componentData, onChange } = this.props;
    const values = {
      data: {
        omcms: {
          images: []
        }
      }
    };
    onChange(componentData, values);
    this.setState({ imageDeleted: true });
    this.toggleDeleteModal();
  };

  render() {
    const { componentData, editingComponentData, submitting } = this.props;
    const { addModalOpen, deleteModalOpen, imageDeleted } = this.state;
    let image = null;
    const componentDataHasImages =
      !imageDeleted && componentData?.data?.omcms?.images[0];
    const editingComponentDataHasImages =
      !imageDeleted &&
      editingComponentData &&
      editingComponentData.id === componentData.id &&
      editingComponentData?.data?.omcms?.images[0];
    if (componentDataHasImages) image = componentData.data.omcms.images[0];
    if (editingComponentDataHasImages)
      image = editingComponentData.data.omcms.images[0];
    return (
      <div>
        <div className="inspector-fields">
          {image ? (
            <div className="selectable-list-item active">
              <FontAwesomeIcon className="component-icon" icon="image" />
              <span className="label">Hero Image</span>
              <button
                className="edit"
                data-testid="editHeroImageButton"
                onClick={this.toggleAddModal}
                type="button"
              >
                <FontAwesomeIcon className="component-icon" icon="pencil-alt" />
              </button>
              <button
                className="delete"
                data-testid="deleteHeroImageButton"
                onClick={this.toggleDeleteModal}
                type="button"
              >
                ×
              </button>
            </div>
          ) : (
            <p className="gray-med no-data">There is no image</p>
          )}
        </div>
        <FileModal
          componentData={componentData}
          editing
          handleSubmit={this.updateHeroComponent}
          isOpen={addModalOpen}
          itemId={image?.id}
          noDescription
          noName
          submitting={submitting}
          toggle={this.toggleAddModal}
        />
        <DeleteConfirmationModal
          handleDelete={this.deleteImage}
          isOpen={deleteModalOpen}
          toggle={this.toggleDeleteModal}
          type="image"
        />
      </div>
    );
  }

  toggleAddModal = () => {
    this.setState({
      addModalOpen: !this.state.addModalOpen
    });
  };

  toggleDeleteModal = () => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen
    });
  };

  updateHeroComponent = async (componentData, values) => {
    const { handleImageUpload, onChange } = this.props;

    // Clone the OMCMS object in case the component contains footnotes data that should be retained
    const omcms = componentData?.data?.omcms
      ? cloneObject(componentData.data.omcms)
      : {};

    if (values.file) {
      await handleImageUpload(values.file).then(itemData => {
        itemData.pos = '1';
        itemData.versions = Object.fromEntries(
          Object.entries(itemData.versions).filter(([key]) =>
            ['large', 'medium', 'original', 'xlarge'].includes(key)
          )
        );
        omcms.images = [itemData];
      });
    }

    if (values.subimage) {
      await handleImageUpload(values.subimage).then(itemData => {
        itemData.versions = Object.fromEntries(
          Object.entries(itemData.versions).filter(([key]) =>
            ['medium', 'original', 'small', 'thumb'].includes(key)
          )
        );
        omcms.subimage = itemData;
      });
    }

    omcms.subtextTitle =
      values.omcms?.subtextTitle?.length > 0 ? values.omcms.subtextTitle : null;
    omcms.subtextBody =
      values.omcms?.subtextBody?.length > 0 ? values.omcms.subtextBody : null;
    omcms.leftGradient = values.omcms?.leftGradient || false;

    const newValues = {
      data: {
        omcms
      }
    };
    onChange(componentData, newValues);
  };
}
