import './ComponentList.scss';

import type { Component, ComponentType } from 'src/types/Component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import SortableTree, {
  getFlatDataFromTree,
  getTreeFromFlatData
} from 'react-sortable-tree';

import { midPoint } from '../../../helpers';
import NodeRenderer from './NodeRenderer';

export type Props = {
  addComponentClick: (
    parentId?: number,
    disabledComponentTypes?: ComponentType[],
    position?: number
  ) => void;
  components: Component[];
  deleteComponentClick: (id: number) => void;
  expandedComponents: number[];
  handleExpandedComponents: (treeFlatData: object[]) => void;
  selectComponent: (id: number, isLocked: boolean) => void;
  selectedComponentId: number;
  updateComponentOrder: (
    component: { id: number },
    componentTwo: { id: number }
  ) => void;
  userEmail: string;
};
type State = { componentInView: number };

export default class ComponentList extends React.Component<Props, State> {
  state = {
    componentInView: null
  };

  canDrop = ({ nextParent, nextPath, node }) => {
    return (
      node.id === this.props.selectedComponentId &&
      !(node.type === 'section' && nextPath.length > 4) &&
      // Don't allow dropping more than 2 components under a section-parallel,
      // or dropping a component with a type of section, section-parallel, or hero
      !(
        nextParent &&
        nextParent.type === 'section-parallel' &&
        (nextParent.children.length > 2 ||
          node.type === 'section' ||
          node.type === 'section-parallel' ||
          node.type === 'hero')
      )
    );
  };

  componentDidMount() {
    this.updateScrollPosition();
    window.addEventListener('scroll', this.updateScrollPosition);
  }

  componentWillUnmount() {
    window.addEventListener('scroll', this.updateScrollPosition);
  }

  moveNode = data => {
    const { nextParentNode, node, treeData } = data;
    const flatData = getFlatDataFromTree({
      getNodeKey: nodeToCheck => nodeToCheck.id,
      ignoreCollapsed: false,
      treeData: treeData
    }).map(({ node: flatNode }) => flatNode);

    let components = flatData;
    if (nextParentNode) {
      node.parentId = nextParentNode.id;
      components = components.filter(
        nodeToCheck => nodeToCheck.parentId === nextParentNode.id
      );
    } else {
      node.parentId = null;
      components = components.filter(
        nodeToCheck => nodeToCheck.parentId === null
      );
    }

    const nodeIndex = components.indexOf(node);
    const startPos =
      components[nodeIndex - 1] && components[nodeIndex - 1].vpos;
    const endPos = components[nodeIndex + 1] && components[nodeIndex + 1].vpos;
    node.vpos = midPoint(startPos, endPos);

    // Set hpos to 0 by default, since we're already updating vpos and saving component data
    node.hpos = 0;
    let siblingNode = null;

    if (nextParentNode && nextParentNode.type === 'section-parallel') {
      if (nodeIndex === 1) {
        siblingNode = components[nodeIndex - 1];
        node.hpos = 1;
      } else {
        siblingNode = components[nodeIndex + 1];
      }
      // Sibling node also needs its hpos updated to the opposite of the node that was moved
      if (siblingNode) siblingNode.hpos = nodeIndex === 1 ? 0 : 1;
    }

    this.props.updateComponentOrder(node, siblingNode);
  };

  render() {
    const {
      addComponentClick,
      components,
      deleteComponentClick,
      selectComponent,
      selectedComponentId,
      userEmail
    } = this.props;
    const { componentInView } = this.state;

    return (
      <div className="sidebar" data-testid="componentList" id="component-list">
        <div>
          <h6 className="sidebar-title">Components</h6>
          <button
            className="add-button"
            onClick={() => addComponentClick()}
            title="Add Component"
            type="button"
          >
            <FontAwesomeIcon icon="plus" />
          </button>
        </div>

        {components?.length > 0 ? (
          <SortableTree
            canDrag={({ node }) => node.id === selectedComponentId}
            canDrop={data => this.canDrop(data)}
            canNodeHaveChildren={node =>
              node.type === 'section' || node.type === 'section-parallel'
            }
            generateNodeProps={() => ({
              componentInView,
              deleteComponentClick,
              selectComponent,
              selectedComponentId,
              userEmail
            })}
            getNodeKey={({ node }) => node.id}
            innerStyle={{ position: 'absolute', top: '20px' }}
            isVirtualized={false}
            maxDepth={5}
            nodeContentRenderer={NodeRenderer}
            onChange={treeData => this.updateComponents(treeData)}
            onMoveNode={data => this.moveNode(data)}
            rowHeight={42}
            scaffoldBlockPxWidth={20}
            treeData={this.treeData(components)}
          />
        ) : (
          <div className="no-components">
            There are no
            <br /> components
          </div>
        )}
      </div>
    );
  }

  treeData = components => {
    const sortedComponents = components
      .map(node => {
        return {
          ...node,
          expanded:
            this.props.expandedComponents.length > 0
              ? this.props.expandedComponents.includes(node.id)
              : true
        };
      })
      .sort((a, b) => {
        if (a.vpos < b.vpos) {
          return -1;
        }
        if (a.vpos > b.vpos) {
          return 1;
        }
        return 0;
      });

    return getTreeFromFlatData({
      flatData: sortedComponents,
      getKey: node => node.id,
      getParentKey: node => node.parentId,
      rootKey: null
    });
  };

  updateComponents = treeData => {
    const flatData = getFlatDataFromTree({
      getNodeKey: node => node.id,
      ignoreCollapsed: false,
      treeData: treeData
    }).map(({ node }) => node);

    this.props.handleExpandedComponents(flatData);
  };

  updateScrollPosition = () => {
    const { components } = this.props;
    const winHeight = window.innerHeight;
    const scrollTop = document.documentElement.scrollTop;
    components?.forEach(component => {
      const canvasComponentId = `canvas-component-${component.id}`;
      const canvasComponent = document.getElementById(canvasComponentId);
      if (canvasComponent) {
        const componentTop =
          canvasComponent.getBoundingClientRect().top + scrollTop;
        if (
          componentTop - winHeight + 100 < scrollTop &&
          componentTop + canvasComponent.offsetHeight - winHeight + 100 >
            scrollTop
        )
          this.setState({ componentInView: component.id });
      }
    });
  };
}
