import './CanvasHero.scss';

import type { Component } from 'src/types/Component';
import type { Image } from 'src/types/Image';

import React from 'react';
import { Button } from 'reactstrap';

import FileModal from '../shared/FileModal';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  handleImageUpload: (image: File) => Promise<Image>;
  onChange: (component: Component, values: Component) => void;
  submitting: boolean;
};
type State = {
  modalOpen: boolean;
};
export default class CanvasHero extends React.Component<Props, State> {
  state = {
    modalOpen: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.editingComponentData !== this.props.editingComponentData)
      this.setState({ modalOpen: false });
  }

  render() {
    const { componentData, editingComponentData, submitting } = this.props;
    const { modalOpen } = this.state;

    let omcmsData = componentData?.data?.omcms;
    if (editingComponentData?.id === componentData.id) {
      omcmsData = editingComponentData.data.omcms;
    }

    let imageUrl = null;
    const componentDataHasImages = omcmsData?.images && omcmsData.images[0];
    if (componentDataHasImages) imageUrl = omcmsData.images[0].url;
    const heroSubimageUrl = omcmsData?.subimage?.url;
    const heroSubtextTitle = omcmsData?.subtextTitle;
    const heroSubtextBody = omcmsData?.subtextBody;
    const heroLeftGradient = omcmsData?.leftGradient;

    const componentHasSubContent =
      heroSubimageUrl || heroSubtextTitle || heroSubtextBody;
    return (
      <div className="overflow-hidden">
        {imageUrl ? (
          <div className={heroLeftGradient ? 'linear-gradient' : ''}>
            {componentHasSubContent && (
              <div className="hero-sub-content">
                {heroSubtextTitle && <h2>{heroSubtextTitle}</h2>}
                {heroSubtextBody && <p>{heroSubtextBody}</p>}
                {heroSubimageUrl && (
                  <img
                    alt="Hero Subimage"
                    className="hero-subimage"
                    src={heroSubimageUrl}
                  />
                )}
              </div>
            )}
            <img alt="Hero" className="hero-image" src={imageUrl} />
          </div>
        ) : (
          <Button color="secondary" onClick={this.toggleModal} outline>
            Add Hero Image
          </Button>
        )}
        <FileModal
          componentData={componentData}
          handleSubmit={this.updateHeroComponent}
          isOpen={modalOpen}
          noDescription
          noName
          submitting={submitting}
          toggle={this.toggleModal}
        />
      </div>
    );
  }

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  };

  updateHeroComponent = async (componentData, values) => {
    const { handleImageUpload, onChange } = this.props;
    const omcms = {
      images: [],
      leftGradient: false,
      subimage: null,
      subtextBody: null,
      subtextTitle: null
    };

    if (values.file) {
      await handleImageUpload(values.file).then(itemData => {
        itemData.pos = '1';
        itemData.versions = Object.fromEntries(
          Object.entries(itemData.versions).filter(([key]) =>
            ['large', 'medium', 'original', 'xlarge'].includes(key)
          )
        );
        omcms.images = [itemData];
      });
    }

    if (values.subimage) {
      await handleImageUpload(values.subimage).then(itemData => {
        itemData.versions = Object.fromEntries(
          Object.entries(itemData.versions).filter(([key]) =>
            ['medium', 'original', 'small', 'thumb'].includes(key)
          )
        );
        omcms.subimage = itemData;
      });
    }

    omcms.subtextTitle =
      values.omcms?.subtextTitle?.length > 0 ? values.omcms.subtextTitle : null;
    omcms.subtextBody =
      values.omcms?.subtextBody?.length > 0 ? values.omcms.subtextBody : null;
    omcms.leftGradient = values.omcms?.leftGradient || false;

    const newValues = {
      data: {
        omcms
      }
    };
    onChange(componentData, newValues);
  };
}
