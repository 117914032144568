import { Reducer } from 'react';

import { SET_ERROR } from '../../redux/actions/Error';
import { BaseAction, BaseState } from '../types/Base';

export const initialState: BaseState = {};

const errorReducer: Reducer<BaseState, BaseAction> = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export default errorReducer;
