import React from 'react';
import { Style } from 'react-notification-system';
import Notifications, {
  NotificationsState
} from 'react-notification-system-redux';
import { connect } from 'react-redux';

import type { RootState } from '../..';

type Props = { notifications: NotificationsState };

class NotificationContainer extends React.Component<Props> {
  render() {
    const { notifications } = this.props;
    const style = {
      NotificationItem: {
        DefaultStyle: {
          fontSize: '1rem',
          padding: '15px',
          whiteSpace: 'pre-wrap'
        }
      },
      Title: {
        DefaultStyle: {
          fontSize: '1.125rem'
        }
      }
    } as Style;

    return <Notifications notifications={notifications} style={style} />;
  }
}

const mapStateToProps = (store: RootState) => {
  return {
    notifications: store.notifications
  };
};

export default connect(mapStateToProps)(NotificationContainer);
