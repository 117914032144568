import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-sortable-tree/style.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSoundcloud,
  faVimeoV,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
  faAngleLeft,
  faAngleRight,
  faArchive,
  faAsterisk,
  faBan,
  faBars,
  faBold,
  faBolt,
  faBookmark,
  faBriefcase,
  faBuilding,
  faCalendar,
  faCaretDown,
  faCaretRight,
  faChartArea,
  faChartBar,
  faChartLine,
  faChartPie,
  faCheckSquare,
  faChevronLeft,
  faClock,
  faCloud,
  faCog,
  faColumns,
  faComment,
  faCompass,
  faCopy,
  faCubes,
  faDollarSign,
  faDownload,
  faEnvelope,
  faExchangeAlt,
  faExternalLinkAlt,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileWord,
  faFont,
  faGem,
  faGlobeAmericas,
  faHeading,
  faHistory,
  faHome,
  faImage,
  faInfoCircle,
  faItalic,
  faKey,
  faLink,
  faListOl,
  faListUl,
  faLock,
  faLongArrowAltRight,
  faMapMarkerAlt,
  faMinus,
  faNetworkWired,
  faPause,
  faPencilAlt,
  faPercent,
  faPlayCircle,
  faPlus,
  faPuzzlePiece,
  faQuoteLeft,
  faSave,
  faSignOutAlt,
  faSquare,
  faStrikethrough,
  faSuperscript,
  faTable,
  faTrash,
  faUnderline,
  faUndo,
  faUniversity,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/react';
import { jwtDecode } from 'jwt-decode';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { setupInterceptors as setupHttpClientInterceptors } from './apiClient';
import App from './App';
import NotificationContainer from './components/NotificationContainer/NotificationContainer';
import { configureStore } from './configureStore';
import { getUserToken } from './helpers';
import { initialState as omcmsReduxAuthState } from './redux/reducers/Auth';

library.add(
  faAngleLeft,
  faAngleRight,
  faArchive,
  faAsterisk,
  faBan,
  faBars,
  faBold,
  faBolt,
  faBookmark,
  faBriefcase,
  faBuilding,
  faCalendar,
  faCaretDown,
  faCaretRight,
  faChartArea,
  faChartBar,
  faChartLine,
  faChartPie,
  faCheckSquare,
  faChevronLeft,
  faClock,
  faCloud,
  faCog,
  faColumns,
  faComment,
  faCompass,
  faCopy,
  faCubes,
  faDollarSign,
  faDownload,
  faEnvelope,
  faExchangeAlt,
  faExternalLinkAlt,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileWord,
  faFont,
  faGem,
  faGlobeAmericas,
  faHeading,
  faHistory,
  faHome,
  faImage,
  faInfoCircle,
  faItalic,
  faKey,
  faLink,
  faListOl,
  faListUl,
  faLock,
  faLongArrowAltRight,
  faMapMarkerAlt,
  faMinus,
  faNetworkWired,
  faPause,
  faPencilAlt,
  faPercent,
  faPlayCircle,
  faPlus,
  faPuzzlePiece,
  faQuoteLeft,
  faSave,
  faSignOutAlt,
  faSoundcloud,
  faSquare,
  faStrikethrough,
  faSuperscript,
  faTable,
  faTrash,
  faUnderline,
  faUndo,
  faUniversity,
  faUsers,
  faYoutube,
  faVimeoV
);

// If a user token exists, decode it, and preload it into redux state
let decodedUserData = null;
const initialReduxState = {
  Auth: omcmsReduxAuthState
};
try {
  const userAccessToken = getUserToken();
  decodedUserData = jwtDecode(userAccessToken);
  // eslint-disable-next-line no-empty
} catch {} // Swallow. No need to take action here. just load an empty state

if (decodedUserData != null) {
  initialReduxState.Auth = { user: decodedUserData };
}

// Sentry configuration
if (
  process.env.REACT_APP_STAGE &&
  process.env.REACT_APP_STAGE !== 'local' &&
  process.env.NODE_ENV !== 'test'
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_STAGE,
    integrations: [Sentry.browserTracingIntegration()]
  });
}

if (decodedUserData?.email) {
  Sentry.setUser({
    email: decodedUserData?.email
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { appReducer, store } = configureStore(initialReduxState);
setupHttpClientInterceptors(store);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <NotificationContainer />
    <App />
  </Provider>
);

export type RootState = ReturnType<typeof appReducer>;
export type AppDispatch = ThunkDispatch<typeof store.dispatch, void, AnyAction>;
