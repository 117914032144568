import './ComponentTitle.scss';

import type { Component } from 'src/types/Component';

import React from 'react';

type Props = {
  componentData: Component;
  depth: number;
  editingComponentData: Component;
};

export default class ComponentTitle extends React.Component<Props> {
  render() {
    const { componentData, depth, editingComponentData } = this.props;
    const currentData =
      editingComponentData && editingComponentData.id === componentData.id
        ? editingComponentData
        : componentData;
    const title = currentData.title;

    return <div className={`component-title depth-${depth}`}>{title}</div>;
  }
}
