import './NodeRenderer.scss';

import type { Component } from 'src/types/Component';

import React, { StyleHTMLAttributes } from 'react';

import ComponentListItem from './ComponentListItem';

type Props = {
  buttons?: React.ReactNode[];
  canDrag?: boolean;
  // Drop target
  canDrop?: boolean;
  className?: string;
  componentInView?: number;
  // Drag and drop API functions
  // Drag source
  connectDragPreview: (node: React.ReactNode) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  connectDragSource: any;
  deleteComponentClick: () => void;
  didDrop: boolean;
  draggedNode?: Record<string, unknown>;
  icons?: React.ReactNode[];
  isDragging: boolean;
  isOver: boolean;
  isSearchFocus?: boolean;
  isSearchMatch?: boolean;
  listIndex?: number;
  lowerSiblingCounts?: number[];
  node: Component;
  parentNode?: Record<string, unknown>; // Needed for dndManager
  path: (number | string)[];
  rowDirection?: string;
  scaffoldBlockPxWidth: number;
  selectComponent: () => void;
  // Custom props
  selectedComponentId?: number;
  style?: StyleHTMLAttributes<HTMLElement>;
  subtitle?: () => React.ReactNode | void;
  swapDepth?: number;
  swapFrom?: number;
  swapLength?: number;
  title?: string;

  toggleChildrenVisibility?: (values: {
    node: Component;
    path: (number | string)[];
    treeIndex: number;
  }) => void;
  treeId: string;
  treeIndex: number;
  userEmail?: string;
};

function isDescendant(older, younger) {
  return (
    !!older.children &&
    typeof older.children !== 'function' &&
    older.children.some(
      child => child === younger || isDescendant(child, younger)
    )
  );
}

class NodeRenderer extends React.Component<Props> {
  public static defaultProps = {
    buttons: [],
    canDrag: false,
    canDrop: false,
    className: '',
    draggedNode: null,
    icons: [],
    isSearchFocus: false,
    isSearchMatch: false,
    parentNode: null,
    style: {},
    subtitle: null,
    swapDepth: null,
    swapFrom: null,
    swapLength: null,
    title: null,
    toggleChildrenVisibility: null
  };

  render() {
    const {
      // These destructured variables are needed even though they're unused to avoid passing them to the
      // div through ...otherProps as invalid HTML attributes
      /* eslint-disable @typescript-eslint/no-unused-vars */
      buttons,
      canDrag,
      canDrop,
      className,
      componentInView,
      connectDragPreview,
      connectDragSource,
      deleteComponentClick,
      didDrop,
      draggedNode,
      icons,
      isDragging,
      isOver, // Not needed, but preserved for other renderers
      isSearchFocus,
      isSearchMatch,
      listIndex,
      lowerSiblingCounts,
      node,
      parentNode, // Needed for dndManager
      path,
      rowDirection,
      scaffoldBlockPxWidth,
      selectComponent,
      selectedComponentId,
      style,
      subtitle,
      swapDepth,
      swapFrom,
      swapLength,
      title,
      toggleChildrenVisibility,
      treeId, // Not needed, but preserved for other renderers
      treeIndex,
      userEmail,
      /* eslint-enable */
      ...otherProps
    } = this.props;

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;

    const nodeContent = connectDragPreview(
      <div
        className={
          'row' +
          (isLandingPadActive ? ' rowLandingPad' : '') +
          (isLandingPadActive && !canDrop ? ' rowCancelPad' : '') +
          (className ? ` ${className}` : '')
        }
        draggable={canDrag}
        style={{
          opacity: isDraggedDescendant ? 0.5 : 1,
          ...style
        }}
      >
        <ComponentListItem
          active={node.id === selectedComponentId ? true : false}
          componentData={node}
          deleteClick={deleteComponentClick}
          inView={componentInView === node.id}
          key={node.id}
          selectComponent={selectComponent}
          toggleChildrenVisibility={() =>
            toggleChildrenVisibility({
              node,
              path,
              treeIndex
            })
          }
          type={node.type}
          userEmail={userEmail}
        />
      </div>
    );

    return (
      <div style={{ height: '100%' }} {...otherProps}>
        <div
          className={'rowWrapper' + (!canDrag ? ' rowWrapperDragDisabled' : '')}
        >
          {canDrag
            ? connectDragSource(nodeContent, { dropEffect: 'copy' })
            : nodeContent}
        </div>
      </div>
    );
  }
}

export default NodeRenderer;
