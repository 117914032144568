import './UserControlPanel.scss';

import React from 'react';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import UserFields from './components/UserFields';

export default class UserControlPanel extends React.Component {
  componentDidMount() {
    document.title = 'User Settings';
  }

  render() {
    return (
      <div data-testid="userControlPanel" id="user-control-panel">
        <Header backLink="/" backLinkText="Home" title="User Settings" />
        <Container>
          <UserFields />
        </Container>
      </div>
    );
  }
}
