import { ActionCreator } from 'redux';

import { BaseAction } from '../types/Base';

export const SET_ERROR = 'SET_ERROR';

export const setError: ActionCreator<BaseAction> = (error: string) => {
  return {
    error,
    type: SET_ERROR
  };
};
