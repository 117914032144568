import './OfferingListItem.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import { Offering } from '../../../types/Offering';

type Props = {
  archiveOffering: () => void;
  cloneOffering: () => void;
  offering: Offering;
  togglePublish: () => void;
  toggleUnpublish: () => void;
};

export default class OfferingListItem extends React.Component<Props> {
  render() {
    const {
      archiveOffering,
      cloneOffering,
      offering,
      togglePublish,
      toggleUnpublish
    } = this.props;

    return (
      <div className="offering-list-item">
        <Link className="title" to={`/offering/${offering.id}`}>
          {offering.name}
        </Link>
        <button
          className="duplicate"
          onClick={cloneOffering}
          title="Clone"
          type="button"
        >
          <FontAwesomeIcon icon="copy" />
        </button>
        <button
          className="archive"
          onClick={archiveOffering}
          title="Archive"
          type="button"
        >
          <FontAwesomeIcon icon="archive" />
        </button>
        {offering.published ? (
          <Button color="success" onClick={toggleUnpublish}>
            Completed
          </Button>
        ) : (
          <Button color="warning" onClick={togglePublish} outline>
            Draft
          </Button>
        )}
      </div>
    );
  }
}
