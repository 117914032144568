import {
  Middleware,
  configureStore as reduxToolkitConfigureStore
} from '@reduxjs/toolkit';
import { createBrowserHistory, History } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import thunk from 'redux-thunk';

import { productionApp } from './constants';
import reducers from './redux/reducers';

const baseHistory = createBrowserHistory();

const { createReduxHistory, routerMiddleware } = createReduxHistoryContext({
  history: baseHistory
});

// Exported so that redux-mock-store can use the same middleware
export const middleware = [routerMiddleware, thunk];

export let history: History;

export function configureStore(initialReduxState) {
  const { appReducer, rootReducer } = reducers(baseHistory);
  const store = reduxToolkitConfigureStore({
    devTools: !productionApp,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
      }).concat(routerMiddleware as Middleware),
    preloadedState: initialReduxState,
    reducer: rootReducer
  });

  history = createReduxHistory(store);

  return { appReducer, store };
}
