import type { AppDispatch } from 'src';
import type { Connector } from 'src/types/Connector';

import { success } from 'react-notification-system-redux';
import { Action, ActionCreator } from 'redux';

import apiClient from '../../apiClient';
import { BaseAction } from '../types/Base';

export const GET_CONNECTORS = 'GET_CONNECTORS';
export const GET_CONNECTORS_SUCCESS = 'GET_CONNECTORS_SUCCESS';
export const GET_CONNECTORS_FAIL = 'GET_CONNECTORS_FAIL';
export const ADD_CONNECTOR = 'ADD_CONNECTOR';
export const ADD_CONNECTOR_SUCCESS = 'ADD_CONNECTOR_SUCCESS';
export const ADD_CONNECTOR_FAIL = 'ADD_CONNECTOR_FAIL';
export const ADD_CONNECTOR_CLOSE = 'ADD_CONNECTOR_CLOSE';
export const DELETE_CONNECTOR = 'DELETE_CONNECTOR';
export const DELETE_CONNECTOR_SUCCESS = 'DELETE_CONNECTOR_SUCCESS';
export const DELETE_CONNECTOR_FAIL = 'DELETE_CONNECTOR_FAIL';

export const getConnectorsStart: ActionCreator<Action> = () => {
  return {
    type: GET_CONNECTORS
  };
};

export const getConnectorsSuccess: ActionCreator<BaseAction<Connector>> = (
  values: Connector
) => {
  return {
    payload: values,
    type: GET_CONNECTORS_SUCCESS
  };
};

export const getConnectorsFail: ActionCreator<Action> = () => {
  return {
    type: GET_CONNECTORS_FAIL
  };
};

export const getConnectors = () => {
  return (dispatch: AppDispatch) => {
    dispatch(getConnectorsStart());
    return apiClient
      .get('/authoring/connectors')
      .then(response => {
        dispatch(getConnectorsSuccess(response.data));
      })
      .catch(error => {
        dispatch(getConnectorsFail());
        throw error;
      });
  };
};

export const addConnectorStart: ActionCreator<Action> = () => {
  return {
    type: ADD_CONNECTOR
  };
};

export const addConnectorSuccess: ActionCreator<
  BaseAction<Connector>
> = values => {
  return {
    payload: values,
    type: ADD_CONNECTOR_SUCCESS
  };
};

export const addConnectorFail: ActionCreator<Action> = () => {
  return {
    type: ADD_CONNECTOR_FAIL
  };
};

export const addConnector = (values: Connector) => {
  return (dispatch: AppDispatch) => {
    dispatch(addConnectorStart());
    return apiClient
      .post('/authoring/connectors', values)
      .then(response => {
        dispatch(addConnectorSuccess(response.data));
      })
      .catch(error => {
        dispatch(addConnectorFail());
        throw error;
      });
  };
};

export const addConnectorClose: ActionCreator<Action> = () => {
  return {
    type: ADD_CONNECTOR_CLOSE
  };
};

export const deleteConnectorStart: ActionCreator<Action> = () => {
  return {
    type: DELETE_CONNECTOR
  };
};

export const deleteConnectorSuccess: ActionCreator<
  BaseAction<Connector>
> = values => {
  return {
    payload: values,
    type: DELETE_CONNECTOR_SUCCESS
  };
};

export const deleteConnectorFail: ActionCreator<Action> = () => {
  return {
    type: DELETE_CONNECTOR_FAIL
  };
};

export const deleteConnector = (id: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(deleteConnectorStart());
    return apiClient
      .delete('/authoring/connectors/' + id)
      .then(response => {
        dispatch(deleteConnectorSuccess(response.data));
        dispatch(
          success({
            title: 'Connector deleted'
          })
        );
        return response.data;
      })
      .catch(error => {
        dispatch(deleteConnectorFail());
        throw error;
      });
  };
};
