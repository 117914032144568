import './InspectorDocuments.scss';

import type { Component } from 'src/types/Component';
import type { Document } from 'src/types/Document';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { midPoint } from '../../../../helpers';
import DeleteConfirmationModal from '../shared/DeleteConfirmationModal';
import FileModal from '../shared/FileModal';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  handleComponentLockStatus: (id: number) => Promise<boolean>;
  handleLockComponent: (id: number) => void;
  handleUploadMedia: (
    component: Component,
    values: Component,
    index?: number
  ) => void;
  onChange: (component: Component, values: Component) => void;
  submitting: boolean;
};
type State = {
  addModalOpen?: boolean;
  deleteModalOpen?: boolean;
  documents?: Document[];
  documentSelected?: number;
};
export default class InspectorDocuments extends React.Component<Props, State> {
  state: State = {
    addModalOpen: false,
    deleteModalOpen: false,
    documents: [],
    documentSelected: null
  };

  componentDidMount() {
    this.setDocuments();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.componentData.id !== this.props.componentData.id ||
      prevProps.editingComponentData !== this.props.editingComponentData
    ) {
      this.setState({ addModalOpen: false });
      this.setDocuments();
    }
  }

  deleteDocument = documentId => {
    const { componentData, onChange } = this.props;
    const { documents } = this.state;
    const newDocuments = documents.filter(
      document => document.id !== documentId
    );
    const values = {
      data: {
        omcms: {
          ...componentData.data.omcms,
          documents: newDocuments
        }
      }
    };
    onChange(componentData, values);
    this.toggleDeleteModal();
  };

  onDragEnd = result => {
    const { destination, draggableId, source } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const { componentData, onChange } = this.props;
    const { documents } = this.state;

    const newDocuments = documents.filter(
      document => draggableId !== document.id
    );
    const nodeIndex = destination.index;
    const startPos =
      newDocuments[nodeIndex - 1] && newDocuments[nodeIndex - 1].pos;
    const endPos = newDocuments[nodeIndex] && newDocuments[nodeIndex].pos;

    const document = documents.find(
      documentToFind => draggableId === documentToFind.id
    );
    document.pos = midPoint(startPos, endPos);
    newDocuments.splice(nodeIndex, 0, document);

    const newValues = {
      data: {
        omcms: {
          documents: newDocuments
        }
      }
    };
    onChange(componentData, newValues);
  };

  onDragStart = () => {
    const { componentData, editingComponentData, handleComponentLockStatus } =
      this.props;
    if (editingComponentData) {
      return;
    }

    handleComponentLockStatus(componentData.id);
  };

  render() {
    const { componentData, handleUploadMedia, submitting } = this.props;
    const { addModalOpen, deleteModalOpen, documents, documentSelected } =
      this.state;

    return (
      <div>
        <button
          className="add-button"
          onClick={() => this.toggleAddModal(null)}
          title="Add Document"
          type="button"
        >
          <FontAwesomeIcon icon="plus" />
        </button>
        <DragDropContext
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
        >
          <Droppable droppableId="droppable">
            {provided => (
              <div
                className="inspector-fields"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {documents.length > 0 ? (
                  documents.map((document, index) => {
                    const fileType = document.originalName.split('.').pop();
                    let fileIcon = 'file-pdf';
                    if (['doc', 'docx'].indexOf(fileType) > -1)
                      fileIcon = 'file-word';
                    if (['xls', 'xlsx'].indexOf(fileType) > -1)
                      fileIcon = 'file-excel';
                    return (
                      <Draggable
                        draggableId={document.id}
                        index={index}
                        key={document.id}
                      >
                        {(draggableProvided, item) => (
                          <div
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            className={`selectable-list-item${
                              item.isDragging ||
                              documentSelected === document.id
                                ? ' active'
                                : ''
                            }`}
                            data-testid={`component${componentData.id}InspectorDocument${index}`}
                            key={document.id}
                            ref={draggableProvided.innerRef}
                          >
                            <FontAwesomeIcon
                              className="component-icon"
                              data-testid="fileIcon"
                              /* @ts-expect-error we add all icons on App.js in order to use this as is */
                              icon={fileIcon}
                            />
                            <span className="label">{document.name}</span>
                            <button
                              className="edit"
                              data-testid="editButton"
                              onClick={_ => this.toggleAddModal(_, document.id)}
                              type="button"
                            >
                              <FontAwesomeIcon
                                className="component-icon"
                                icon="pencil-alt"
                              />
                            </button>
                            <button
                              className="delete"
                              data-testid="deleteButton"
                              onClick={_ =>
                                this.toggleDeleteModal(_, document.id)
                              }
                              type="button"
                            >
                              ×
                            </button>
                          </div>
                        )}
                      </Draggable>
                    );
                  })
                ) : (
                  <p className="gray-med no-data">There are no documents</p>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <FileModal
          componentData={componentData}
          editing={documentSelected ? true : false}
          handleSubmit={(componentDataToSubmit, values, changedDocument) => {
            if (changedDocument) {
              const documentIndex = documents.findIndex(
                document => document.id === documentSelected
              );
              handleUploadMedia(componentDataToSubmit, values, documentIndex);
            } else if (!documentSelected) {
              handleUploadMedia(componentDataToSubmit, values);
            } else {
              this.updateDocument(values);
            }
          }}
          isOpen={addModalOpen}
          itemId={documentSelected}
          items={documents}
          submitting={submitting}
          toggle={this.toggleAddModal}
          type="document"
        />
        <DeleteConfirmationModal
          handleDelete={() => this.deleteDocument(documentSelected)}
          isOpen={deleteModalOpen}
          toggle={this.toggleDeleteModal}
          type="document"
        />
      </div>
    );
  }

  setDocuments = () => {
    const { componentData, editingComponentData } = this.props;
    let documents = [];
    const componentDataHasDocuments =
      componentData?.data?.omcms?.documents?.length > 0;
    const editingComponentDataHasDocuments =
      editingComponentData?.data?.omcms?.documents?.length > 0;
    if (componentDataHasDocuments)
      documents = componentData.data.omcms.documents.slice();
    if (editingComponentData?.id === componentData.id) {
      documents = editingComponentDataHasDocuments
        ? editingComponentData.data.omcms.documents.slice()
        : [];
    }

    documents.sort((a, b) => {
      if (a.pos < b.pos) {
        return -1;
      }
      if (a.pos > b.pos) {
        return 1;
      }
      return 0;
    });

    this.setState({ documents: documents });
  };

  toggleAddModal = async (_?, documentId?: number) => {
    const {
      componentData,
      editingComponentData,
      handleComponentLockStatus,
      handleLockComponent
    } = this.props;

    if ((!this.state.addModalOpen || documentId) && !editingComponentData) {
      const isLocked = await handleComponentLockStatus(componentData.id);
      if (isLocked) {
        return;
      }
      handleLockComponent(componentData.id);
    }

    const newState: State = {
      addModalOpen: !this.state.addModalOpen
    };
    if (!this.state.addModalOpen) newState.documentSelected = documentId;
    else newState.documentSelected = null;
    this.setState(newState);
  };

  toggleDeleteModal = async (_?, documentId?: number) => {
    const {
      componentData,
      editingComponentData,
      handleComponentLockStatus,
      handleLockComponent
    } = this.props;

    if (documentId && !editingComponentData) {
      const isLocked = await handleComponentLockStatus(componentData.id);
      if (isLocked) {
        return;
      }
      handleLockComponent(componentData.id);
    }

    const newState: State = {
      deleteModalOpen: !this.state.deleteModalOpen
    };
    if (!this.state.deleteModalOpen) newState.documentSelected = documentId;
    else newState.documentSelected = null;
    this.setState(newState);
  };

  updateDocument = values => {
    const { componentData, onChange } = this.props;
    const { documentSelected } = this.state;
    const { documents } = this.state;
    const documentIndex = documents.findIndex(
      document => document.id === documentSelected
    );
    documents[documentIndex].name = values.name;
    const newValues = {
      data: {
        omcms: {
          ...componentData.data.omcms,
          documents: documents
        }
      }
    };
    onChange(componentData, newValues);
  };
}
