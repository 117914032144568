import './AddComponentModal.scss';

import type { ComponentType } from 'src/types/Component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';

import ButtonPlus from '../../../components/ButtonPlus/ButtonPlus';

type Props = {
  addComponent: (type: string) => void;
  disabledComponentTypes: string[];
  isOpen: boolean;
  submitting: boolean;
  toggle: (
    parentId?: number,
    disabledComponentTypes?: ComponentType[],
    position?: number
  ) => void;
};
type State = { selectedType: string };

export default class AddComponentModal extends React.Component<Props, State> {
  state = {
    selectedType: null
  };

  getLabel = (type: string) => {
    if (type === 'cash-flow') return 'Cash Flow Scenario';
    if (type === 'metrics') return 'Key Metrics';
    if (type === 'section-parallel') return 'Parallel Columns';
    if (type === 'sources-and-uses') return 'Sources And Uses';
    return type;
  };

  handleButtonClick = (type: string) => {
    this.setState({ selectedType: type });
  };

  render() {
    const { addComponent, disabledComponentTypes, isOpen, submitting, toggle } =
      this.props;
    const { selectedType } = this.state;

    // These can be uncommented as they're implemented

    let componentTypes = [
      {
        icon: 'puzzle-piece',
        type: 'section'
      },
      {
        icon: 'pause',
        type: 'section-parallel'
      },
      {
        icon: 'font',
        type: 'text'
      },
      {
        icon: 'file-image',
        type: 'image'
      },
      {
        icon: 'image',
        type: 'hero'
      },
      {
        icon: 'table',
        type: 'table'
      },
      {
        icon: 'map-marker-alt',
        type: 'map'
      },
      {
        icon: 'play-circle',
        type: 'media'
      },
      {
        icon: 'percent',
        type: 'metrics'
      },
      {
        icon: 'file-pdf',
        type: 'documents'
      },
      {
        icon: 'columns',
        type: 'sources-and-uses'
      },
      {
        icon: 'chart-bar',
        type: 'cash-flow'
      }
    ];

    if (disabledComponentTypes) {
      componentTypes = componentTypes.filter(
        component => disabledComponentTypes.indexOf(component.type) === -1
      );
    }

    return (
      <Modal
        centered
        data-testid="addComponentModal"
        id="add-component-modal"
        isOpen={isOpen}
        onClosed={() => this.setState({ selectedType: null })}
        toggle={() => toggle()}
      >
        <ModalHeader toggle={() => toggle()}>Add Component</ModalHeader>
        <ModalBody>
          <Row>
            {componentTypes.map(component => (
              <Col key={component.type} xs="2">
                <button
                  className={`component-button ${
                    selectedType === component.type ? 'active' : ''
                  }`}
                  onClick={() => this.handleButtonClick(component.type)}
                  title={`Add ${component.type} component`}
                  type="button"
                >
                  {/* @ts-expect-error we import every icon in App.js in order to use this as is */}
                  <FontAwesomeIcon icon={component.icon} />
                  <span>{this.getLabel(component.type)}</span>
                </button>
              </Col>
            ))}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggle()}>
            Cancel
          </Button>
          <ButtonPlus
            color="primary"
            data-testid="addComponentSubmitButton"
            disabled={selectedType ? false : true}
            loading={submitting}
            onClick={() => addComponent(selectedType)}
            style={{ width: '190px' }}
          >
            Add Component
          </ButtonPlus>
        </ModalFooter>
      </Modal>
    );
  }
}
