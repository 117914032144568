import './Offerings.scss';

import React from 'react';
import { Container } from 'reactstrap';

import Header from '../../components/Header/Header';
import OfferingList from './components/OfferingList';

export default class Offerings extends React.Component {
  componentDidMount() {
    document.title = 'Offerings';
  }

  render() {
    return (
      <div data-testid="offerings" id="offerings">
        <Header title="Offerings" />
        <Container>
          <OfferingList />
        </Container>
      </div>
    );
  }
}
