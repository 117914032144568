import './GooglePlaceAutocomplete.scss';

import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Input } from 'reactstrap';

type Props = {
  field: {
    name: string;
  };
  form: {
    setFieldValue: (field: string, value: string) => void;
  };
};

export default class GooglePlaceAutocomplete extends React.Component<Props> {
  onAddressChange = address => {
    const {
      field: { name },
      form: { setFieldValue }
    } = this.props;
    setFieldValue(name, address);
  };

  render() {
    const { field, ...custom } = this.props;

    return (
      <PlacesAutocomplete {...field} onChange={this.onAddressChange}>
        {({ getInputProps, getSuggestionItemProps, loading, suggestions }) => (
          <div id="address-wrap">
            <Input
              {...custom}
              {...getInputProps({
                className: 'location-search-input'
              })}
            />

            <div className="autocomplete-dropdown-container">
              {loading && <div className="suggestion-item">Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item active'
                  : 'suggestion-item';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                    key={suggestion}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
