import './CanvasComponent.scss';

import type {
  Component,
  ComponentMetricData,
  ComponentType
} from 'src/types/Component';
import type { Image } from 'src/types/Image';
import type { MetricData } from 'src/types/MetricData';
import type { OfferingData } from 'src/types/Offering';
import type { UIState } from 'src/types/UIState';

import React from 'react';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

import CanvasCashFlow from './canvas/CanvasCashFlow';
import CanvasDocuments from './canvas/CanvasDocuments';
import CanvasHero from './canvas/CanvasHero';
import CanvasImage from './canvas/CanvasImage';
import CanvasKeyMetrics from './canvas/CanvasKeyMetrics';
import CanvasMap from './canvas/CanvasMap';
import CanvasMedia from './canvas/CanvasMedia';
import CanvasParallel from './canvas/CanvasParallel';
import CanvasSection from './canvas/CanvasSection';
import CanvasSourcesAndUses from './canvas/CanvasSourcesAndUses';
import CanvasTable from './canvas/CanvasTable';
import CanvasText from './canvas/CanvasText';
import ComponentTitle from './shared/ComponentTitle';

type Props = {
  active?: boolean;
  addComponent?: (id: number, types: ComponentType[]) => void;
  componentData?: Component;
  components?: Component[];
  componentUIState?: UIState[];
  depth?: number;
  editingComponentData?: Component;
  handleAddOfferingData?: (data: ComponentMetricData) => Promise<void>;
  handleComponentLockStatus?: (id: number) => Promise<boolean>;
  handleGetOfferingData?: (noLoading: true) => void;
  handleImageUpload?: (image: File) => Promise<Image>;
  handleLockComponent?: (id: number) => void;
  handleMapUpdate?: (component: Component, values: Component) => void;
  handleSaveOfferingData?: (id: number, data: MetricData) => Promise<void>;
  handleUploadMedia?: (
    component: Component,
    values: unknown,
    editingItemIndex: number
  ) => void;
  loading?: boolean;
  offeringData?: OfferingData[];
  onChange?: (component: Component, values: Component) => void;
  selectComponent?: (id: number, locked?: boolean) => void;
  selectedComponentId?: number;
  selectedLocation?: Record<string, unknown>;
  submitting?: boolean;
  updateComponentUIState?: (
    componentId: number,
    field: number | string,
    value: unknown
  ) => void;
  userEmail?: string;
};

export default class Canvas extends React.Component<Props> {
  render() {
    const {
      active,
      componentData,
      components,
      depth,
      editingComponentData,
      loading,
      selectComponent,
      userEmail
    } = this.props;
    const locked =
      componentData.lockedBy && componentData.lockedBy !== userEmail
        ? componentData.lockedBy
        : null;
    const elementId = `canvas-component-${componentData.id}`;
    const type = componentData.type;
    let hasChildren = false;
    components.forEach(component => {
      if (component.parentId === componentData.id) hasChildren = true;
    });

    return (
      <div
        className={`canvas-component canvas-component-${type} ${
          active ? 'active' : ''
        } ${locked ? 'locked' : ''} ${loading ? 'loading' : ''}`}
        data-testid={`canvasComponent${componentData.id}`}
        id={elementId}
        onKeyDown={() => {}}
        onMouseDown={() => {
          // Allow selecting of parallel columns from the canvas only if they have no children,
          // to prevent click conflicts between parent/child
          if (
            (type !== 'section-parallel' && !active) ||
            (type === 'section-parallel' && !hasChildren && !active)
          )
            selectComponent(componentData.id, locked !== null);
        }}
        role="button"
        tabIndex={0}
      >
        {type !== 'section' && (
          <ComponentTitle
            componentData={componentData}
            depth={depth}
            editingComponentData={editingComponentData}
          />
        )}
        {this.renderComponent()}
        {locked && (
          <UncontrolledPopover
            placement="right-start"
            target={elementId}
            trigger="hover"
          >
            <PopoverBody>Component locked by {locked}</PopoverBody>
          </UncontrolledPopover>
        )}
      </div>
    );
  }

  renderComponent = () => {
    const {
      active,
      addComponent,
      componentData,
      components,
      componentUIState,
      depth,
      editingComponentData,
      handleAddOfferingData,
      handleComponentLockStatus,
      handleGetOfferingData,
      handleImageUpload,
      handleLockComponent,
      handleMapUpdate,
      handleSaveOfferingData,
      handleUploadMedia,
      offeringData,
      onChange,
      selectedComponentId,
      selectedLocation,
      submitting,
      updateComponentUIState
    } = this.props;

    const currentComponentUIState =
      componentUIState.find(component => component.id === componentData.id) ||
      null;

    const componentProps = {
      active: active,
      componentData: componentData,
      editingComponentData: editingComponentData,
      onChange: onChange
    };

    let component;

    switch (componentData.type) {
      case 'cash-flow':
        component = (
          <CanvasCashFlow
            {...componentProps}
            handleComponentLockStatus={handleComponentLockStatus}
            handleLockComponent={handleLockComponent}
          />
        );
        break;
      case 'documents':
        component = (
          <CanvasDocuments
            {...componentProps}
            handleUploadMedia={handleUploadMedia}
            submitting={submitting}
          />
        );
        break;
      case 'hero':
        component = (
          <CanvasHero
            {...componentProps}
            handleImageUpload={handleImageUpload}
            submitting={submitting}
          />
        );
        break;
      case 'image':
        component = (
          <CanvasImage
            {...componentProps}
            handleComponentLockStatus={handleComponentLockStatus}
            handleLockComponent={handleLockComponent}
            handleUploadMedia={handleUploadMedia}
            submitting={submitting}
            uiState={currentComponentUIState}
            updateComponentUIState={updateComponentUIState}
          />
        );
        break;
      case 'map':
        component = (
          <CanvasMap
            {...componentProps}
            handleMapUpdate={handleMapUpdate}
            selectedComponentId={selectedComponentId}
            selectedLocation={selectedLocation}
            submitting={submitting}
          />
        );
        break;
      case 'media':
        component = <CanvasMedia {...componentProps} submitting={submitting} />;
        break;
      case 'metrics':
        component = (
          <CanvasKeyMetrics
            {...componentProps}
            handleAddOfferingData={handleAddOfferingData}
            handleGetOfferingData={handleGetOfferingData}
            handleSaveOfferingData={handleSaveOfferingData}
            offeringData={offeringData}
          />
        );
        break;
      case 'section':
      case 'section-parallel':
        /* eslint-disable no-case-declarations */
        let hasChildren = false;
        const children = [];
        /* eslint-enable */
        components.forEach(componentToCheck => {
          if (componentToCheck.parentId === componentData.id) {
            hasChildren = true;
            children.push(componentToCheck);
          }
        });
        component =
          componentData.type === 'section' ? (
            <CanvasSection
              {...componentProps}
              addComponent={addComponent}
              depth={depth}
              hasChildren={hasChildren}
            />
          ) : (
            <CanvasParallel {...this.props} parallelChildren={children} />
          );
        break;
      case 'sources-and-uses':
        component = (
          <CanvasSourcesAndUses
            {...componentProps}
            handleComponentLockStatus={handleComponentLockStatus}
            handleLockComponent={handleLockComponent}
          />
        );
        break;
      case 'table':
        component = (
          <CanvasTable
            {...componentProps}
            handleComponentLockStatus={handleComponentLockStatus}
            handleLockComponent={handleLockComponent}
          />
        );
        break;
      case 'text':
        component = (
          <CanvasText
            {...componentProps}
            handleComponentLockStatus={handleComponentLockStatus}
            handleLockComponent={handleLockComponent}
          />
        );
        break;
      default:
        component = <CanvasSection {...componentProps} />;
    }

    return component;
  };
}
