import './Loading.scss';

import React from 'react';

const Loading: React.FC = () => (
  <div data-testid="loadingMask" id="loading-mask">
    <div className="loader" data-testid="loader" />
  </div>
);

export default Loading;
