import mudder from 'mudder/dist/mudder.cjs';

export const getUserToken = () => localStorage.getItem('omcmsAccessToken');

export const setUserToken = (accessToken: string) =>
  localStorage.setItem('omcmsAccessToken', accessToken);

export const clearUserToken = () => localStorage.removeItem('omcmsAccessToken');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = (callback: (...args: any[]) => void, wait: number) => {
  let timeout = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    const next = () => callback(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
  };
};

export const snakeCase = (str: string) => str.replace(/ /g, '_').toLowerCase();

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        b: parseInt(result[3], 16),
        g: parseInt(result[2], 16),
        r: parseInt(result[1], 16)
      }
    : null;
};

// Returns either black or white based on the hex code of the color passed,
// white for dark colors, black for light colors

export const getToneFromBackground = (hexColor: string) => {
  const rgb = hexToRgb(hexColor);
  let tone = null;
  if (rgb) {
    if (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 150) tone = '#000';
    else tone = '#fff';
  }
  return tone;
};

// Returns mid-point alphabet of start and end alphabet boundry
export const midPoint = (start?: string, end?: string) =>
  mudder.alphabet.mudder(start, end)[0];

// Returns alphabet for give index [0..25]
export const alphabet = (index: number | string) =>
  mudder.alphabet.num2sym[index].toUpperCase();

// Returns a copy of an object
export const cloneObject = (object: object) => {
  if (typeof object !== 'object') return null;
  return JSON.parse(JSON.stringify(object));
};
