import React, { SVGProps } from 'react';

const GoogleLogo: React.FC<SVGProps<SVGAElement>> = () => (
  <svg
    data-testid="googleLogo"
    enableBackground="new 0 0 70 50"
    height="50px"
    viewBox="0 0 70 50"
    width="70px"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g id="Sign-in">
      <g id="Sign-In" transform="translate(-585.000000, -730.000000)">
        <g id="Popup" transform="translate(304.000000, 205.000000)">
          <rect
            fill="#DC4E41"
            height="21.833"
            width="34.333"
            x="301.667"
            y="539.5"
          />
          <path
            d="M284,525h64c1.656,0,3,1.343,3,3v44c0,1.656-1.344,3-3,3h-64c-1.657,0-3-1.344-3-3v-44
              C281,526.343,282.343,525,284,525z"
            id="base-background"
          />
        </g>
      </g>
      <g>
        <path
          d="M46.603,24.479c0-0.81-0.066-1.626-0.206-2.423H35.145v4.593h6.443c-0.267,1.481-1.126,2.792-2.384,3.625
          v2.98h3.844C45.305,31.176,46.603,28.108,46.603,24.479z"
          fill="#4285F4"
        />
        <path
          d="M35.145,36.134c3.218,0,5.931-1.058,7.908-2.88l-3.845-2.98c-1.069,0.728-2.45,1.14-4.058,1.14
          c-3.113,0-5.751-2.1-6.698-4.923h-3.967v3.072C26.511,33.591,30.636,36.134,35.145,36.134z"
          fill="#34A853"
        />
        <path
          d="M28.448,26.49c-0.5-1.481-0.5-3.085,0-4.567v-3.072h-3.963c-1.691,3.37-1.691,7.342,0,10.711L28.448,26.49
          z"
          fill="#FBBC04"
        />
        <path
          d="M35.145,16.997c1.701-0.026,3.345,0.613,4.576,1.789l0,0l3.406-3.406
          c-2.156-2.025-5.019-3.138-7.982-3.103c-4.509,0-8.634,2.542-10.659,6.575l3.963,3.072C29.391,19.097,32.033,16.997,35.145,16.997
          z"
          fill="#EA4335"
        />
      </g>
    </g>
  </svg>
);

export default GoogleLogo;
