import type { Connector } from 'src/types/Connector';

import { Reducer } from 'redux';

import {
  ADD_CONNECTOR,
  ADD_CONNECTOR_CLOSE,
  ADD_CONNECTOR_FAIL,
  ADD_CONNECTOR_SUCCESS,
  DELETE_CONNECTOR,
  DELETE_CONNECTOR_FAIL,
  DELETE_CONNECTOR_SUCCESS,
  GET_CONNECTORS,
  GET_CONNECTORS_FAIL,
  GET_CONNECTORS_SUCCESS
} from '../../redux/actions/Connectors';
import { BaseAction, BaseState } from '../types/Base';

type ConnectorsState = BaseState & {
  connectorAdded?: Connector;
  connectors?: Connector[];
};

export const initialState: ConnectorsState = {};

const connectorsReducer: Reducer<
  ConnectorsState,
  BaseAction<ConnectorsState, BaseAction>
  // eslint-disable-next-line default-param-last
> = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONNECTOR:
      return {
        ...state,
        error: undefined,
        submitting: true
      };
    case ADD_CONNECTOR_SUCCESS:
      return {
        ...state,
        connectorAdded: action.payload,
        submitting: false
      };
    case ADD_CONNECTOR_FAIL:
      return {
        ...state,
        error: action.error,
        submitting: false
      };
    case ADD_CONNECTOR_CLOSE:
      return {
        ...state,
        connectorAdded: undefined
      };
    case GET_CONNECTORS:
      return {
        ...state,
        error: undefined,
        loading: true
      };
    case GET_CONNECTORS_SUCCESS:
      return {
        ...state,
        connectors: action.payload,
        loading: false
      };
    case GET_CONNECTORS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case DELETE_CONNECTOR:
      return {
        ...state,
        error: undefined,
        submitting: true
      };
    case DELETE_CONNECTOR_SUCCESS:
      return {
        ...state,
        submitting: false
      };
    case DELETE_CONNECTOR_FAIL:
      return {
        ...state,
        error: action.error,
        submitting: false
      };
    default:
      return state;
  }
};

export default connectorsReducer;
