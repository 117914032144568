import './ButtonPlus.scss';

import React from 'react';
import { Button, ButtonProps } from 'reactstrap';

type Props = ButtonProps;

export default class ButtonPlus extends React.Component<Props> {
  render() {
    const { children, className, loading, ...otherProps } = this.props;
    let classStr = className;
    if (loading) {
      classStr += ' loading';
      otherProps.disabled = true;
    }

    return (
      <Button {...otherProps} className={classStr}>
        {!loading && children}
      </Button>
    );
  }
}
